import {
  Icon,
  IconButton,
  IconButtonProps,
  useButtonGroup,
} from "@chakra-ui/react";
import * as log from "loglevel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaMicrophoneSlash as MicrophoneMutedIcon,
  FaMicrophone as MicrophoneUnmutedIcon,
} from "react-icons/fa";
import { useNotificationToast } from "../../hooks";

const isMicrophoneSupported = !!navigator.mediaDevices;

export function MicrophoneButton({
  isMicrophoneMuted,
  toggleAudioInput,
  ...props
}: {
  isMicrophoneMuted: boolean;
  toggleAudioInput: () => void;
} & Omit<
  IconButtonProps,
  "isDisabled" | "isLoading" | "aria-label" | "icon" | "onClick"
>) {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const [isEnablingMicrophone, setIsEnablingMicrophone] = useState(false);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(false);
  const { isDisabled } = useButtonGroup();

  return (
    <IconButton
      isDisabled={!isMicrophoneSupported || isDisabled}
      onClick={async () => {
        if (isMicrophoneEnabled) {
          // mic was already active once, let's toggle it
          toggleAudioInput();
          return;
        }

        // mic wasn't active, let's try to enable it
        // try to enable the microphone (if not yet enabled). To do so, ask for permission
        try {
          setIsEnablingMicrophone(true);
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setIsEnablingMicrophone(false);
          setIsMicrophoneEnabled(true);
          toggleAudioInput();
        } catch (error) {
          setIsEnablingMicrophone(false);
          setIsMicrophoneEnabled(false);
          // user denied the microphone permission
          log.error("Error enabling microphone", error);
          toast({
            title: t("spectator.microphone.failed"),
            description: t("spectator.microphone.unmuting-failed"),
            status: "error",
          });
        }
      }}
      icon={
        <Icon
          as={isMicrophoneMuted ? MicrophoneMutedIcon : MicrophoneUnmutedIcon}
        />
      }
      isLoading={isEnablingMicrophone}
      paddingX={4}
      {...{
        "aria-label": isMicrophoneMuted
          ? "Unmute Microphone"
          : "Mute Microphone",
      }}
      {...props}
    />
  );
}
