import {
  Box,
  ButtonGroup,
  CircularProgress,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Navigate, Link as RouterLink } from "react-router-dom";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import {
  ActiveSessionButton,
  BrandedSkeleton,
  ConfirmSessionAbortDialog,
} from "../components";
import { ClearIcon, WarningIcon } from "../components/icons";
import { Logo } from "../components/Logo";
import { useDcvPreference } from "../components/useDcvPreference";
import { selectSessionDevice } from "../features/devicesSlice";
import {
  selectSessionOverviewReceived,
  selectSessionState,
  SessionDataExtended,
} from "../features/sessionSlice";
import {
  useActiveOrganizationQuery,
  useAppSelector,
  useClientApplicationVersionInformation,
  usePortalName,
} from "../hooks";
import { Application } from "../hooks/types";
import { useApplicationForApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { SESSION_STATE } from "../session/session-state";
import { terminateSession } from "../signalR";
import { DcvPreferencesButton } from "../streamer/dcv/DcvPreferencesButton";
import { Streamer, type StreamerType } from "../streamer/Streamer";
import { StreamerContextProvider } from "../streamer/StreamerContext";
import { StreamerControls } from "../streamer/StreamerControls";
import { routes } from "../utils/routes";
import { SessionFromDifferentOrganizationWarning } from "./session-page/SessionFromDifferentOrganizationWarning";
import { SessionPageContext } from "./session-page/SessionPageContext";
import { SessionPreview } from "./session-page/SessionPreview";
import { StreamingProtocolSwitcher } from "./session-page/StreamingProtocolSwitcher";
import { useIsDcvAvailable } from "./useIsDcvAvailable";
import { useIsReadyToStream } from "./useIsReadyToStream";

const StreamerTypeEnumParam = createEnumParam<StreamerType>([
  "innoactive",
  "dcv",
]);

export function SessionPage() {
  const { data: organization } = useActiveOrganizationQuery();
  const session = useAppSelector(selectSessionState);
  const sessionOverviewReceived = useAppSelector(selectSessionOverviewReceived);
  const abortSessionDialogState = useDisclosure();
  const productName = usePortalName();
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );
  const useDcvIfAvailable = useDcvPreference();
  const [streamerType, setStreamerType] = useQueryParam(
    "streamer",
    withDefault(
      StreamerTypeEnumParam,
      useDcvIfAvailable ? ("dcv" as const) : ("innoactive" as const),
    ),
  );
  const isReadyToStream = useIsReadyToStream();
  const isDcvAvailable = useIsDcvAvailable();

  // reset streamer type if dcv is not available
  useEffect(() => {
    if (isReadyToStream && !isDcvAvailable) {
      setStreamerType("innoactive");
    }
  }, [isDcvAvailable, isReadyToStream, setStreamerType]);

  const abortSession = () => {
    terminateSession(session.id!);
  };

  if (!sessionOverviewReceived) {
    return (
      <Flex justifyContent={"center"} alignItems={"center"} flexGrow={1}>
        <CircularProgress isIndeterminate />
      </Flex>
    );
  }

  // if we ended the session manually (or it errored), redirect back to the application's detail page
  if (session.state >= SESSION_STATE.ENDING && app) {
    return (
      <Navigate to={generatePath(routes.application.details, { id: app.id })} />
    );
  } else if (session.state < SESSION_STATE.REQUESTED) {
    return <Navigate to={routes.home} />;
  }

  // if the session is from another organization, show a warning and allow to end the session
  if (
    organization &&
    session.id &&
    Number(session.organizationId) !== organization?.id
  ) {
    return (
      <SessionFromDifferentOrganizationWarning
        abortSession={abortSession}
        organizationId={Number(session.organizationId)}
      />
    );
  }

  return (
    <Flex height="full" width="full" flexDirection={"column"}>
      <Helmet>
        <title>
          Session {app?.name ?? ""}
          {app?.name ? " - " : ""}
          {productName}
        </title>
      </Helmet>
      <StreamerContextProvider>
        <SessionPageHeader
          session={session}
          streamerType={streamerType}
          setStreamerType={setStreamerType}
          app={app}
          onAbortSession={abortSessionDialogState.onOpen}
        />

        <SessionPageContext.Provider
          value={{
            showAbortSessionDialog: abortSessionDialogState.onOpen,
            abortSession,
          }}
        >
          <Flex
            position="relative"
            overflow="hidden"
            flexGrow="1"
            minHeight={["20vh", "30vh", undefined] as unknown as string}
            flexDirection="column"
          >
            {isReadyToStream ? (
              <Streamer streamerType={streamerType} />
            ) : (
              <SessionPreview session={session} />
            )}
          </Flex>
          <ConfirmSessionAbortDialog
            isOpen={abortSessionDialogState.isOpen}
            onClose={abortSessionDialogState.onClose}
            onConfirm={abortSession}
          />
        </SessionPageContext.Provider>
      </StreamerContextProvider>
    </Flex>
  );
}

function SessionPageHeader({
  session,
  app,
  streamerType,
  setStreamerType,
  onAbortSession,
}: {
  session: SessionDataExtended;
  app?: Application;
  streamerType: StreamerType;
  setStreamerType: (streamerType: StreamerType) => void;
  onAbortSession: () => void;
}) {
  const { t } = useTranslation();
  const activeDevice = useAppSelector(selectSessionDevice);
  const { isOutdated } = useClientApplicationVersionInformation(activeDevice);
  const isDcvAvailable = useIsDcvAvailable();
  const isReadyToStream = useIsReadyToStream();

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      width="full"
      padding={2}
      spacing={2}
      alignItems={{ base: "normal", md: "center" }}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Link
        as={RouterLink}
        _focus={undefined}
        to="/"
        marginRight="6"
        variant={"unstyled"}
        display={{ base: "none", md: "block" }}
      >
        <Logo height={10} />
      </Link>
      <BrandedSkeleton
        isLoaded={!!app?.name || !session.id}
        display={{ base: "none", md: "block" }}
      >
        <Heading as="h3" size="xs">
          {app?.name || "Loading ..."}
        </Heading>
      </BrandedSkeleton>
      {session.isCloudRenderedVr ? (
        <BrandedSkeleton isLoaded={!!session.id}>
          <HStack>
            {activeDevice ? (
              <Text fontSize="xs" color={"chakra-subtle-text"}>
                <Trans
                  i18nKey="session.running_on"
                  values={{
                    name: activeDevice.name,
                  }}
                  components={{ bold: <strong /> }}
                />
              </Text>
            ) : null}
            {isOutdated ? (
              <Tooltip
                hasArrow
                label={`Client is outdated, please update soon.`}
              >
                <IconButton
                  aria-label="Outdated version warning"
                  icon={<Icon as={WarningIcon} />}
                  color="yellow.200"
                  variant="link"
                  size="xs"
                />
              </Tooltip>
            ) : null}
          </HStack>
        </BrandedSkeleton>
      ) : null}
      <Spacer display={{ base: "none", md: "block" }} />
      <HStack
        justifyContent={"space-between"}
        flexDirection={{ base: "row-reverse", md: "row" }}
      >
        {isReadyToStream ? (
          <>
            <Divider
              orientation="vertical"
              display={{ base: "none", md: "block" }}
            />
            <StreamerControls />
            <Divider
              orientation="vertical"
              display={{ base: "none", md: "block" }}
            />
          </>
        ) : null}
        <HStack>
          {isDcvAvailable ? (
            <>
              <Spacer />
              <Box flexGrow={1}>
                <StreamingProtocolSwitcher
                  streamerType={streamerType}
                  setStreamerType={setStreamerType}
                />
              </Box>
            </>
          ) : null}
          {streamerType === "dcv" && isDcvAvailable && isReadyToStream ? (
            <>
              <DcvPreferencesButton />
              <Divider
                orientation="vertical"
                display={{ base: "none", md: "block" }}
              />
            </>
          ) : null}
        </HStack>
      </HStack>
      <ButtonGroup isAttached size={{ base: "md", md: "sm" }}>
        <ActiveSessionButton flexGrow={[1, 1, 0]} />
        <IconButton
          onClick={onAbortSession}
          aria-label={t("session.end")}
          icon={<Icon as={ClearIcon} />}
          colorScheme="red"
          variant="solid"
        />
      </ButtonGroup>
    </Stack>
  );
}
