import { CircularProgress, CircularProgressLabel } from "@chakra-ui/progress";
import { Button, ColorProps, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useBranding } from "../../../branding";
import { ExpectedWaitTime } from "../../../components";
import { SessionDataExtended } from "../../../features/sessionSlice";
import { useApplicationForApplicationBuildQuery } from "../../../hooks/useApplicationBuildsQuery";
import { useSessionPageContext } from "../SessionPageContext";

export function SessionLoadingState({
  session,
}: {
  session: SessionDataExtended;
}) {
  const { t } = useTranslation();
  const { showAbortSessionDialog } = useSessionPageContext();
  const { data: branding } = useBranding();
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );
  const color = useMemo(
    () =>
      app?.images?.[0]?.image || branding?.app_image_placeholder
        ? "white"
        : "chakra-body-text",
    [app?.images, branding],
  );

  return (
    <>
      <CircularProgress
        value={(session.progress || 0) * 100}
        isIndeterminate={!session.isDesktop}
        color="brand.300"
        thickness="3px"
        // FIXME: Shit
        size={[120, 140, 160, 200] as unknown as string}
      >
        <CircularProgressLabel
          fontSize={["lg", "lg", "x-large", "xx-large"]}
          color={color}
        >
          {session.isDesktop ? (
            Math.floor((session.progress ?? 0) * 100) + "%"
          ) : (
            <ExpectedWaitTime session={session} />
          )}
        </CircularProgressLabel>
      </CircularProgress>
      <ProgressInfo session={session} color={color} />
      <Button
        variant="outline"
        marginTop={[2, 2, 4]}
        onClick={showAbortSessionDialog}
        color={color}
        borderColor={color}
        _hover={{
          background: "blackAlpha.500",
        }}
        display={{ base: "none", md: "block" }}
      >
        {t("session.abort")}
      </Button>
    </>
  );
}

function ProgressInfo({
  session,
  color,
}: {
  session: Pick<
    SessionDataExtended,
    "isDesktop" | "message" | "expectedWaitTimeSec"
  >;
} & Pick<ColorProps, "color">) {
  const { t } = useTranslation();

  if (session.isDesktop) {
    return <></>;
  }

  if (session.message === "This operation is taking longer than expected") {
    return (
      <Text fontStyle={"italic"} fontSize={"lg"} color={color}>
        {t("session.taking_longer_than_expected")}
      </Text>
    );
  }

  if (!session.expectedWaitTimeSec) {
    return (
      <Text fontStyle={"italic"} fontSize={"lg"} color={color}>
        {t("session.calculating_wait_time")}
      </Text>
    );
  }
}
