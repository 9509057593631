import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import * as Sentry from "@sentry/react";
import { backOff } from "exponential-backoff";
import log from "loglevel";
import { cloudRenderingSessionManagementServiceUrl } from "./config";
import {
  clearDevices,
  updateDevices,
  updateInstalledApps,
} from "./features/devicesSlice";
import {
  appInstalled,
  appLaunched,
  issueHandled,
  notification,
  reportIssue,
  selectSessionState,
  sessionCreated,
  sessionError,
  sessionOverview,
  sessionProgress,
  sessionRequested,
  sessionTerminated,
  setConnectionState,
  setSessionState,
} from "./features/sessionSlice";
import { ApplicationBuildId } from "./hooks/types";
import { SESSION_STATE } from "./session/session-state";
import {
  AppInstalledMessage,
  AppLaunchedMessage,
  ClientIdentificationListMessage,
  ClientIdentificationMessage,
  CloudRenderedSessionCreatedMessage,
  DeviceType,
  ErrorMessage,
  InstalledAppsMessage,
  IssueHandledMessage,
  NotificationMessage,
  ReportedIssue,
  RequestSessionMessage,
  SessionCreatedMessage,
  SessionOverviewMessage,
  SessionStartupProgressMessage,
  SessionType,
} from "./session/types";
import { store } from "./store";
import { getUniqueIdForApplication } from "./utils/unique-ids";
import { version } from "./utils/version";

const clientIdentification: ClientIdentificationMessage = {
  identifier: getUniqueIdForApplication(),
  name: "Portal (Web)",
  type: DeviceType.Browser,
  version: version,
};

function getConnectionString() {
  const baseUrl = `${cloudRenderingSessionManagementServiceUrl}/hub/sessions`;

  const identificationQueryString = new URLSearchParams(
    // convert an object to a dictionary
    JSON.parse(JSON.stringify(clientIdentification)),
  );

  const url = baseUrl + "?" + identificationQueryString.toString();
  return url;
}

let token: string | undefined = undefined;

export function setToken(newToken: string) {
  token = newToken;

  if (_connection) return;

  createSignalRConnection();
}

let _connection: HubConnection | undefined = undefined;

export function createSignalRConnection() {
  _connection = new HubConnectionBuilder()
    .withUrl(getConnectionString(), {
      accessTokenFactory: () => token ?? "",
      withCredentials: false,
    })
    .build();

  // add any listeners
  // Provide information about this application upon request
  _connection.on("RequestIdentification", () => {
    _connection!
      .invoke("ClientIdentification", clientIdentification)
      .catch((err) => log.error(err));
  });
  // remember any connected clients
  _connection.on(
    "ClientIdentificationList",
    (data: ClientIdentificationListMessage) =>
      store.dispatch(updateDevices(data)),
  );
  _connection.on("InstalledApps", (data: InstalledAppsMessage) => {
    store.dispatch(updateInstalledApps(data));
  });
  _connection.on("SessionOverview", (data: SessionOverviewMessage) => {
    log.debug("Existing Session received", data);
    store.dispatch(sessionOverview(data));
  });
  _connection.on(
    "SessionCreated",
    (data: SessionCreatedMessage | CloudRenderedSessionCreatedMessage) => {
      log.debug("Session Created", data);
      store.dispatch(sessionCreated(data));
    },
  );

  _connection.on("SessionTerminated", (data: { id: string }) => {
    log.debug("Session Terminated", data);
    store.dispatch(sessionTerminated(data));
  });

  _connection.on("StartupProgress", (data: SessionStartupProgressMessage) => {
    log.debug("Startup Progress", data);
    store.dispatch(sessionProgress(data));
  });

  _connection.on("AppLaunched", (data: AppLaunchedMessage) => {
    log.debug("App launched", data.sessionId, data.deviceIdentifier);
    store.dispatch(appLaunched(data));
  });

  _connection.on("AppInstalled", (data: AppInstalledMessage) => {
    log.debug("Connect Command", data);
    store.dispatch(appInstalled(data));
  });

  _connection.on("ReportIssue", (data: ReportedIssue) => {
    log.debug("ReportIssue Command", data);
    store.dispatch(reportIssue(data));
  });

  _connection.on("IssueHandled", (data: IssueHandledMessage) => {
    log.debug("Issue Handled Command", data);
    store.dispatch(issueHandled(data));
  });

  _connection.on("SessionError", (data: ErrorMessage) => {
    log.debug("Session Error", data);
    store.dispatch(sessionError(data));
  });

  _connection.on("Notification", (data: NotificationMessage) => {
    log.debug("Notification", data);
    store.dispatch(notification(data));
  });

  // handle any changes in the connection state (e.g. dropped connection)
  const handleConnectionChange = () => {
    if (_connection) store.dispatch(setConnectionState(_connection.state));
    // without connection, no devices or session
    store.dispatch(clearDevices());
  };
  _connection.onreconnecting(handleConnectionChange);
  _connection.onclose(() => {
    handleConnectionChange();
    connectWithBackoff();
  });

  _connection.onreconnected(() => {
    if (_connection) {
      store.dispatch(setConnectionState(_connection.state));
    }
  });

  // establish the connection (and retry with exponential backoff if it fails)
  const connectWithBackoff = () => {
    backOff(
      () => {
        if (!token) throw new Error("token not set");

        return _connection!.start();
      },
      {
        maxDelay: 10000,
        numOfAttempts: Infinity,
      },
    )
      .catch(() => {
        const error = new Error(
          "Could not connect to Session Management at " + _connection!.baseUrl,
        );
        log.error(error);
        Sentry.captureException(error);
      })
      .finally(() => store.dispatch(setConnectionState(_connection!.state)));
  };

  connectWithBackoff();
}

export async function terminateSession(sessionId: string) {
  if (!sessionId) {
    Sentry.captureException(
      new Error(
        "Attempted to terminate session without having an active session",
      ),
    );
    return;
  }
  try {
    store.dispatch(setSessionState(SESSION_STATE.ENDING));
    if (_connection)
      await _connection.invoke("TerminateSession", {
        Id: sessionId,
      });
  } catch (err) {
    store.dispatch(
      sessionError({
        message: "Error while attempting to terminate session",
        sessionId: sessionId,
      }),
    );

    log.debug("Error while attempting to terminate session", err);
    Sentry.captureException(err);
  }
}

type SessionRequestArgs = {
  appId: ApplicationBuildId;
  type: SessionType;
  deviceIdentifier: string;
  launchArguments: string;
  organizationId: string | number;
  renderRegion?: string;
  vmSize?: string;
  forceColdVm?: boolean;
  encryptVrStream?: boolean;
  vmImage?: string;
  virtualMachineId?: string;
  debugModeEnabled?: boolean;
  withStreamingGateway?: boolean;
};

export async function requestSession({
  appId,
  type,
  deviceIdentifier,
  launchArguments,
  organizationId,
  renderRegion,
  vmSize,
  forceColdVm = false,
  encryptVrStream,
  vmImage,
  virtualMachineId,
  debugModeEnabled = false,
  withStreamingGateway = false,
}: SessionRequestArgs) {
  if (
    !renderRegion &&
    (type === SessionType.CloudRenderedVR ||
      type === SessionType.CloudRenderedNonVR)
  ) {
    log.error("Cannot launch a cloud rendered session without a region");
    return;
  }

  const requestSessionArgs: RequestSessionMessage = {
    appId: appId.toString(),
    deviceIdentifier,
    extraLaunchArguments: launchArguments,
    organizationId: organizationId.toString(),
    renderRegion,
    vmSize: vmSize,
    vmImage: vmImage,
    forceColdVm: forceColdVm,
    cloudXREncryption: encryptVrStream,
    debugModeEnabled,
    virtualMachineId,
    deployStreamingGateway: withStreamingGateway,
  };
  const currentSession = selectSessionState(store.getState());
  const isSessionWithSameConfiguration = (
    Object.keys(requestSessionArgs) as (keyof RequestSessionMessage)[]
  ).every((key) => {
    if (
      key === "forceColdVm" ||
      key === "virtualMachineId" ||
      key === "deployStreamingGateway"
    ) {
      return !requestSessionArgs[key];
    } else if (key === "appId") {
      return (
        currentSession.applicationBuildId === Number(requestSessionArgs.appId)
      );
    }

    return currentSession[key] === requestSessionArgs[key];
  });
  if (
    isSessionWithSameConfiguration &&
    currentSession.state < SESSION_STATE.ENDED
  ) {
    log.debug("Session with same parameters already running");
    return;
  }

  try {
    store.dispatch(sessionRequested({ ...requestSessionArgs, type }));
    await _connection?.invoke("RequestSession", requestSessionArgs);
  } catch (err) {
    store.dispatch(sessionError({ message: (err as Error).message }));
    log.error("Error while attempting to create a new session", err);
    Sentry.captureException(err);
  }
}

export async function sendSessionActivity(sessionId: string) {
  try {
    await _connection?.invoke("SessionActivity", {
      SessionId: sessionId,
    });
  } catch (err) {
    log.error("Failed", err);
  }
}

export async function sendEnableDebug(sessionId: string) {
  try {
    await _connection?.invoke("EnableDebugMode", {
      SessionId: sessionId,
    });
    return true;
  } catch (err) {
    log.error("Failed", err);
    return false;
  }
}

export async function sendIssueHandled(handledIssueInfo: IssueHandledMessage) {
  try {
    await _connection?.invoke("IssueHandled", handledIssueInfo);
  } catch (err) {
    log.error("Failed", err);
  }
}

export async function sendIssueReport(
  issueInfo: Omit<ReportedIssue, "sourceDeviceIdentifier">,
) {
  try {
    await _connection?.invoke("ReportIssue", issueInfo);
  } catch (err) {
    log.error("Failed", err);
  }
}
