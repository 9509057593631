import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { DcvViewer } from "./dcv/DcvViewer";
import { InteractiveSpectator } from "./interactive-spectator/InteractiveSpectator";

export type StreamerType = "innoactive" | "dcv";

export function Streamer({ streamerType }: { streamerType: StreamerType }) {
  const session = useAppSelector(selectSessionState);

  return (
    <>
      {streamerType === "dcv" && session.streamingGatewayEndpoint ? (
        <DcvViewer
          dcvEndpoint={`https://${session.streamingGatewayEndpoint}`}
        />
      ) : session.ipAddress && streamerType === "innoactive" ? (
        <InteractiveSpectator
          sessionId={session.id}
          host={session.ipAddress}
          height="100%"
        />
      ) : null}
    </>
  );
}
