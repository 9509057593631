import {
  Flex,
  FlexProps,
  Heading,
  Icon,
  Link,
  Stack,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { orientation } from "o9n";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { isError } from "remeda";
import { useDebouncedCallback } from "use-debounce";
import "webrtc-adapter";
import { DragHandle } from "../../components/DragHandle";
import { InfoIcon } from "../../components/icons";
import { forceRelayForIce, iceServers } from "../../config";
import {
  addPressedKeyCode,
  mouseMovement,
  removePressedKeyCode,
  resetDownedMouseButtons,
  resetPressedKeyCodes,
  selectInteractiveSpectator,
  selectInteractiveSpectatorConnectionState,
  setConnectionState,
  setIsNotReceivingFrames,
  updateDownedMouseButton,
} from "../../features/interactiveSpectatorSlice";
import {
  useAppDispatch,
  useAppSelector,
  useNotificationToast,
} from "../../hooks";
import { isIOS, isMobile } from "../../utils/browser-support";
import { StreamerControls } from "../StreamerControls";
import { StreamerRef } from "../types";
import { useStreamerContext } from "../useStreamerContext";
import { ActionType, CommandType, keys } from "./capture-daemon-protocol";
import { ConnectionStateOverlay } from "./ConnectionStateOverlay";
import { ButtonAction, Coordinates2D, MouseButton } from "./constants";
import {
  discardEvent,
  getCaptureDaemonMouseEvent,
  getCaptureDaemonWheelEvent,
  getCoordiantesFromMouseEvent,
  getCoordinatesFromTouchEvent,
  getCoordinatesRelativeToVideo,
  isSupportedButtonEvent,
  stopEventPropagation,
} from "./helpers";
import { RemoteUrlOpenerOverlay } from "./RemoteUrlOpenerOverlay";
import {
  LocalWebsocketSignaler,
  Signaler,
  SignalerInterface,
  SignalerType,
  SignalingEventType,
} from "./Signaler";

const MULTITOUCH_WAIT_DELAY_MILLISECONDS = 50;

function waitForFirstFrame(videoElement: HTMLVideoElement, timeoutInMs = 5000) {
  return new Promise<void>((resolve, reject) => {
    // wait for maximum of 5 seconds for the first frame
    const timeout = setTimeout(() => {
      reject("Timeout waiting for first frame");
    }, timeoutInMs);

    const onFirstFrame = () => {
      clearTimeout(timeout);
      resolve();
    };

    if (videoElement.readyState >= 2) {
      // Already have frames
      onFirstFrame();
      return;
    }

    // Modern approach using requestVideoFrameCallback
    if ("requestVideoFrameCallback" in HTMLVideoElement.prototype) {
      videoElement.requestVideoFrameCallback(() => {
        onFirstFrame();
      });
    } else {
      // Fallback approach
      videoElement.onloadeddata = () => {
        onFirstFrame();
      };
    }
  });
}

const isMultiTouchEvent = (event: React.TouchEvent) => {
  // for touchstart event, we need to check the complete list of touches not just changed ones,
  // see https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent/changedTouches
  if (event.type === "touchstart") {
    return event.touches.length > 1;
  }
  return event.changedTouches.length > 1;
};

// https://w3c.github.io/webrtc-pc/#dom-rtcconfiguration
const peerConfiguration: RTCConfiguration = {
  iceServers,
  iceTransportPolicy: forceRelayForIce ? "relay" : "all",
};

const Video = chakra("video");

interface InteractiveSpectatorProps {
  width?: string | number;
  height?: string | number;
  sessionId?: string;
}

const secondsWithoutFramesThreshold = 5;

export const InteractiveSpectator = forwardRef<
  StreamerRef,
  InteractiveSpectatorProps &
    FlexProps & { host: string; signaling?: SignalerType }
>(function InteractiveSpectator(
  {
    width,
    height,
    sessionId,
    host,
    signaling = SignalerType.SessionManagement,
    ...props
  },
  ref,
) {
  const {
    isMicrophoneMuted,
    isFullscreen,
    isAudioMuted,
    setIsAudioMuted,
    setIsAudioOutputBlockedByBrowserPolicy,
    setWindows: setAvailableWindows,
    pasteFromClipboard,
    toggleFullscreen,
    setIsFullscreen,
    isConnected,
    setIsConnected,
  } = useStreamerContext();
  const { connectionState, connectionError } = useAppSelector(
    selectInteractiveSpectatorConnectionState,
  );

  const video = useRef<HTMLVideoElement>(null);
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const fullscreenContainerRef = useRef<HTMLDivElement>(null);
  const dataChannels = useRef<RTCDataChannel[] | null>(null);
  const audioStream = useRef<MediaStream | null>(null);
  const { isNotReceivingFrames, pressedKeyCodes, downedMouseButtonIds } =
    useAppSelector(selectInteractiveSpectator);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const multiTouchTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const toast = useNotificationToast();
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const setMousePosition = useDebouncedCallback(
    () => dispatch(mouseMovement()),
    1000,
    { maxWait: 1000 },
  );
  const [shouldEnableMicrophone, setShouldEnableMicrophone] = useState(false);

  // set the shouldenable microphone flag if the user wants to unmute (for the first time), afterwards unmuting will just happen via the dedicated effect
  // but the first time enabling the microphone requires a reconnect since an audio track is added to the peer connection
  // FIXME: should be possible without reconnect
  useEffect(() => {
    if (isMicrophoneMuted === false) {
      setShouldEnableMicrophone(true);
    }
  }, [isMicrophoneMuted]);

  const sendUserInputToCaptureDaemon = useCallback((data: unknown) => {
    if (!dataChannels.current) {
      log.warn("Data channel not open yet, cannot send data", data);
      return;
    }

    // distribute data to all open data channels (with label "control")
    for (const channel of dataChannels.current.filter(
      (channel) => channel.label === "control",
    )) {
      channel.readyState === "open" && channel.send(JSON.stringify(data));
    }
  }, []);

  const sendMousePositionToCaptureDaemon = useCallback(
    (coords: Coordinates2D) => {
      sendUserInputToCaptureDaemon({
        t: CommandType.MousePosition,
        ...coords,
      });
      setMousePosition();
    },
    [sendUserInputToCaptureDaemon, setMousePosition],
  );

  const sendMouseEventToCaptureDaemon = useCallback(
    (
      buttonId: number,
      type: "up" | "down" | undefined,
      coords: Coordinates2D,
    ) => {
      if (!type) {
        log.error("unsupported event type", type);
      } else {
        dispatch(updateDownedMouseButton({ buttonId, change: type }));
      }
      // ensure to also update the mouse position prior to clicking
      sendMousePositionToCaptureDaemon(coords);
      sendUserInputToCaptureDaemon(
        getCaptureDaemonMouseEvent({ button: buttonId, type }),
      );
    },
    [dispatch, sendMousePositionToCaptureDaemon, sendUserInputToCaptureDaemon],
  );

  const sendWheelEventToCaptureDaemon = useCallback(
    (deltaY: number) => {
      sendUserInputToCaptureDaemon(getCaptureDaemonWheelEvent(deltaY));
    },
    [sendUserInputToCaptureDaemon],
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current) {
        return;
      }

      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );

      // multitouch --> simulate button 2 / right mouse button down
      sendMouseEventToCaptureDaemon(
        isMultiTouchEvent(event) ? MouseButton.Right : MouseButton.Left,
        ButtonAction.Down,
        relativeCoords,
      );
    },
    [sendMouseEventToCaptureDaemon],
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      // if we're dealing with a touch event and we just started touching, delay the rest of the logic a bit so we
      // can decide whether it will become a multitouch or not
      if (multiTouchTimeoutId.current || isMultiTouchEvent(event)) {
        if (multiTouchTimeoutId.current) {
          clearTimeout(multiTouchTimeoutId.current);
        }
        multiTouchTimeoutId.current = undefined;
        handleTouchStart(event);
      } else {
        multiTouchTimeoutId.current = setTimeout(() => {
          multiTouchTimeoutId.current = undefined;
          handleTouchStart(event);
        }, MULTITOUCH_WAIT_DELAY_MILLISECONDS);
      }

      discardEvent(event);
    },
    [handleTouchStart],
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current) {
        return;
      }
      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      // don't send invalid coordiantes
      if (relativeCoords.x === -1 || relativeCoords.y === -1) return;
      sendMousePositionToCaptureDaemon(relativeCoords);
      stopEventPropagation(event);
    },
    [sendMousePositionToCaptureDaemon],
  );

  const onTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current) {
        return;
      }

      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );

      // release the pressed mouse button (whatever it is)
      downedMouseButtonIds.forEach((buttonId) =>
        sendMouseEventToCaptureDaemon(
          buttonId,
          ButtonAction.Up,
          relativeCoords,
        ),
      );

      discardEvent(event);
    },
    [sendMouseEventToCaptureDaemon, downedMouseButtonIds],
  );

  const onMouseEvent = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!video.current) return;
      if (isSupportedButtonEvent(event) === false) return;

      const coords = getCoordiantesFromMouseEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      sendMouseEventToCaptureDaemon(
        event.button,
        event.type === "mousedown"
          ? "down"
          : event.type === "mouseup"
            ? "up"
            : undefined,
        relativeCoords,
      );
      discardEvent(event);
    },
    [sendMouseEventToCaptureDaemon],
  );

  const onWheelEvent = useCallback(
    (event: React.WheelEvent<HTMLElement>) => {
      if (!video.current) return;
      if (isSupportedButtonEvent(event) === false) return;
      sendWheelEventToCaptureDaemon(event.deltaY);
      discardEvent(event);
    },
    [sendWheelEventToCaptureDaemon],
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!video.current) {
        return;
      }
      const coords = getCoordiantesFromMouseEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      // don't send invalid coordiantes
      if (relativeCoords.x === -1 || relativeCoords.y === -1) return;
      sendMousePositionToCaptureDaemon(relativeCoords);
    },
    [sendMousePositionToCaptureDaemon],
  );

  const pasteToRemote = useCallback(
    (value: string) => {
      sendUserInputToCaptureDaemon({
        action: ActionType.Paste,
        value,
      });
    },
    [sendUserInputToCaptureDaemon],
  );

  const onKeyDown = useCallback(
    (code: string) => {
      if (
        code === "KeyV" &&
        (pressedKeyCodes.includes("ControlLeft") ||
          pressedKeyCodes.includes("CommandLeft"))
      ) {
        pasteFromClipboard();
        return;
      }

      const key = keys[code];
      dispatch(addPressedKeyCode(code));
      key &&
        sendUserInputToCaptureDaemon({
          t: CommandType.KeyDown,
          v: keys[code],
        });
    },
    [
      pressedKeyCodes,
      dispatch,
      sendUserInputToCaptureDaemon,
      pasteFromClipboard,
    ],
  );

  const onKeyDownReact = useCallback(
    (event: React.KeyboardEvent) => {
      onKeyDown(event.code);
      discardEvent(event);
    },
    [onKeyDown],
  );

  const onKeyUp = useCallback(
    (code: string) => {
      const key = keys[code];
      dispatch(removePressedKeyCode(code));
      key &&
        sendUserInputToCaptureDaemon({
          t: CommandType.KeyUp,
          v: keys[code],
        });
    },
    [dispatch, sendUserInputToCaptureDaemon],
  );

  const onKeyUpReact = useCallback(
    (event: React.KeyboardEvent) => {
      onKeyUp(event.code);
      discardEvent(event);
    },
    [onKeyUp],
  );

  const onFocusLost = useCallback(
    (event: React.UIEvent) => {
      isConnected && video.current?.blur();

      // release all pressed keyboard buttons
      pressedKeyCodes.forEach((code) => {
        keys[code] &&
          sendUserInputToCaptureDaemon({ t: CommandType.KeyUp, v: keys[code] });
      });

      // release all downed mouse buttons
      downedMouseButtonIds.forEach((downedButton) => {
        sendUserInputToCaptureDaemon(
          getCaptureDaemonMouseEvent({
            button: downedButton,
            type: "up",
          }),
        );
      });
      // reset mouse position to center
      sendMousePositionToCaptureDaemon({ x: 0.5, y: 0.5 });
      dispatch(resetDownedMouseButtons());
      dispatch(resetPressedKeyCodes());
      stopEventPropagation(event);
    },
    [
      isConnected,
      pressedKeyCodes,
      downedMouseButtonIds,
      sendMousePositionToCaptureDaemon,
      dispatch,
      sendUserInputToCaptureDaemon,
    ],
  );

  const onWindowChanged = useCallback(
    (windowHandle: number) => {
      sendUserInputToCaptureDaemon({
        action: ActionType.SetActiveWindow,
        value: windowHandle,
      });
    },
    [sendUserInputToCaptureDaemon],
  );

  useEffect(() => {
    // effect to limit video size to the available space / maximum received video size
    if (!video.current) {
      return;
    }

    const _video = video.current;

    function _setVideoSize() {
      // Ensure metadata is loaded
      if (_video.videoWidth && _video.videoHeight) {
        setVideoSize({
          width: _video.videoWidth,
          height: _video.videoHeight,
        });
      }
    }

    // Listen for the 'loadedmetadata' event to get video dimensions
    _video.addEventListener("loadedmetadata", _setVideoSize);

    return () => {
      _video.removeEventListener("loadedmetadata", _setVideoSize);
    };
  }, []);

  useEffect(() => {
    if (video.current === null) {
      return;
    }

    // initialize a new media stream to be displayed in the video
    const remoteStream = new MediaStream();
    const _video = video.current;
    _video.srcObject = remoteStream;

    // wait for the first frame to be received before showing the video
    waitForFirstFrame(_video)
      .then(() => {
        // first frame received
        log.debug("First frame received.");
        setConnectionState("connected");
      })
      .catch((error) => {
        log.error("Failed to receive first frame", error);
        setIsNotReceivingFrames(true);
      })
      .finally(() => {
        setIsConnected(true);
      });

    // initialize a new peer connection
    const _peerConnection = new RTCPeerConnection(peerConfiguration);
    peerConnection.current = _peerConnection;

    // initialize Signaler
    let signaler: SignalerInterface;
    if (signaling === SignalerType.WebsocketSelfhosted) {
      log.info("Using local signaling server for interactive spectator.");
      signaler = new LocalWebsocketSignaler();
    } else if (signaling === SignalerType.SessionManagement) {
      if (!sessionId || !host) {
        return;
      }
      signaler = new Signaler(sessionId, host);
    } else {
      log.error("Unsupported signaling type", signaling);
      return;
    }
    let offerReceived: RTCSessionDescriptionInit;
    let answerSent: RTCSessionDescriptionInit;
    const iceCandidates: RTCIceCandidate[] = [];
    // start signaling
    signaler
      .on(SignalingEventType.IceCandidateReceived, (iceCandidate) => {
        if (_peerConnection.remoteDescription) {
          _peerConnection.addIceCandidate(iceCandidate).catch(log.debug);
        } else {
          iceCandidates.push(iceCandidate);
        }
      })
      .on(SignalingEventType.OfferReceived, async (sdp) => {
        if (!_peerConnection) {
          return;
        }

        offerReceived = sdp;
        await _peerConnection
          .setRemoteDescription(
            new RTCSessionDescription({
              type: "offer",
              sdp: sdp["answer"],
            }),
          )
          .catch((error) => {
            Sentry.captureException(
              new Error("Failed to set remote description, error: " + error),
              {
                extra: {
                  offer: offerReceived,
                  answer: answerSent,
                },
              },
            );
          });
        const answer = await _peerConnection.createAnswer();
        answerSent = answer;
        await _peerConnection.setLocalDescription(answer);
        signaler.sendOfferAnswer(answer);

        // add any ice candidates we might have already received
        iceCandidates.forEach((candidate) =>
          _peerConnection.addIceCandidate(candidate).catch(log.debug),
        );
      })
      .on(SignalingEventType.ConnectionError, (error) => {
        log.error(error);

        setIsConnected(false);

        // set the connection state to failed unless it's just an aborted connection
        if (!error?.message?.includes("stopped during negotiation")) {
          dispatch(setConnectionState("failed"));
        }
      });

    if (shouldEnableMicrophone) {
      // enable audio input (microphone) if not muted
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          dispatch(setConnectionState("connecting"));
          audioStream.current = stream;
          stream.getTracks().forEach((track) => {
            _peerConnection.addTrack(track);
          });
          signaler.start();
        })
        .catch(function (err) {
          dispatch(setConnectionState("disconnected"));
          setIsConnected(false);
          log.warn(err.message);
        });
      dispatch(setConnectionState("new"));
    } else {
      signaler.start();
      // when signaling starts, we already want to be in connecting state
      dispatch(setConnectionState("connecting"));
    }

    // try to autoplay the video
    _video.muted = false;
    const autoPlayAttemptPromise = _video.play();

    autoPlayAttemptPromise
      .then(() => {
        setIsAudioMuted(false);
        // Autoplay started!
        log.debug("Autoplay started.");
        setIsAudioOutputBlockedByBrowserPolicy(false);
      })
      .catch(async (error) => {
        // ignore aborts
        if (
          isError(error) &&
          (error.name === "AbortError" ||
            error.message.includes("The play() request was interrupted by a."))
        ) {
          return;
        }

        // Autoplay was prevented.
        log.debug("Autoplay was prevented. Muting video.", error);
        _video.muted = true;
        setIsAudioMuted(true);

        try {
          await _video.play();
          // Autoplay started!
          log.debug("Autoplay started with muted video.");
          setIsAudioOutputBlockedByBrowserPolicy(true);
        } catch (error_1) {
          log.error(
            "Failed to start video playback even if video muted.",
            error_1,
          );
        }
      });

    _peerConnection.addEventListener("track", (event) => {
      remoteStream.addTrack(event.track);
    });

    _peerConnection.addEventListener("connectionstatechange", () => {
      if (_peerConnection) {
        if (_peerConnection.connectionState === "connected") {
          // nothing to do here, we'll wait for the first frame before considering us fully connected
        }

        dispatch(setConnectionState(_peerConnection.connectionState));
        if (_peerConnection.connectionState === "failed") {
          Sentry.captureException(new Error("Failed to connect."), {
            extra: {
              offer: offerReceived,
              answer: answerSent,
            },
          });
        }
      }
    });

    _peerConnection.addEventListener(
      "icecandidate",
      (evt) => evt.candidate && signaler.sendIceCandidate(evt.candidate),
      false,
    );

    _peerConnection.addEventListener("icecandidateerror", (evt) => {
      const e = evt as RTCPeerConnectionIceErrorEvent;
      log.warn("IceCandidate ecountered error code: " + e.errorCode, e);
    });

    // open a data channel to send keyboard and mouse input data
    // FIXME: legacy versions of capture daemon rely on the frontend to create a data channel with the name "control"
    //        newer versions create the data channel on the capture daemon side and we should just listen for it
    //        for this purpose, we currently broadcast it to both data channels (either created by the frontend or the capture daemon)
    const setupDataChannel = (channel: RTCDataChannel) => {
      if (!dataChannels.current) {
        dataChannels.current = [];
      }
      dataChannels.current.push(channel);
      channel.onmessage = (event: MessageEvent) => {
        log.debug("Received data from data channel", event.data);
        const data = JSON.parse(event.data);
        if (data.action) {
          switch (data.action) {
            case ActionType.Copy:
              data.value && navigator.clipboard.writeText(data.value);
              break;
            case ActionType.AvailableWindows:
              log.debug("Available windows", data.value);
              setAvailableWindows(data.value);
              break;
            case ActionType.CursorChange:
              // cursor change
              _video.style.cursor = getCssCursorForWindowsCursor(data.value);
              break;
            default:
              break;
          }
        }
      };
    };
    setupDataChannel(_peerConnection.createDataChannel("control"));
    _peerConnection.addEventListener("datachannel", (event) => {
      log.debug("Data channel opened", event);
      setupDataChannel(event.channel);
    });

    return () => {
      if (audioStream.current !== null) {
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      signaler.stop();
      if (_peerConnection.connectionState !== "new") {
        _peerConnection.close();
        dispatch(setConnectionState("closed"));
        setIsConnected(false);
      }
      dataChannels.current = null;
      _video.srcObject = null;

      // reset audio information
      setIsAudioOutputBlockedByBrowserPolicy(false);
    };
  }, [
    sessionId,
    toast,
    t,
    dispatch,
    host,
    signaling,
    setAvailableWindows,
    shouldEnableMicrophone,
    setIsAudioMuted,
    setIsAudioOutputBlockedByBrowserPolicy,
    setIsConnected,
  ]);

  useEffect(() => {
    // detect when no frames are received for a certain time period
    let secondsWithoutFrames = 0;
    const interval = setInterval(() => {
      if (isConnected) {
        peerConnection.current?.getStats().then((stats) => {
          stats.forEach((stat) => {
            // Check if we are receiving video frames and adopt the state accordingly.
            if (stat.type === "inbound-rtp" && stat.kind === "video") {
              // Frames received are only total frames received since the connection was established.
              if (stat.framesReceived === 0) {
                if (secondsWithoutFrames === secondsWithoutFramesThreshold) {
                  !isNotReceivingFrames &&
                    dispatch(setIsNotReceivingFrames(true));
                } else {
                  secondsWithoutFrames++;
                }
              } else {
                // As soon as we get frames we can reset and stop.
                isNotReceivingFrames &&
                  dispatch(setIsNotReceivingFrames(false));
                secondsWithoutFrames = 0;
              }
            }
          });
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (isNotReceivingFrames) {
        dispatch(setIsNotReceivingFrames(false));
      }
    };
  }, [isConnected, isNotReceivingFrames, dispatch]);

  useEffect(() => {
    // effect to log some stats about the connection
    if (isConnected) {
      peerConnection.current?.getStats().then((stats) => {
        let localCandidateId = "";
        let remoteCandidateId = "";
        stats.forEach((stat) => {
          if (stat.type === "candidate-pair" && stat.nominated === true) {
            localCandidateId = stat.localCandidateId;
            remoteCandidateId = stat.remoteCandidateId;
          }
        });
        stats.forEach((stat) => {
          if (stat.id === localCandidateId) {
            log.info("Local candidate", stat);
          } else if (stat.id === remoteCandidateId) {
            log.info("Remote candidate", stat);
          }
        });
      });
    }
  }, [isConnected]);

  const copyToClipboard = useCallback(() => {
    // sends a message to the capture daemon to copy the current selection to the clipboard, the result will be received in the data channel, see ActionType.Copy
    // FIXME: might be better to send an actual command instead of the CTRL+C key combination
    const wasControlDown = pressedKeyCodes.includes("ControlLeft");
    const wasKeyCDown = pressedKeyCodes.includes("KeyC");

    onKeyDown("ControlLeft");
    onKeyDown("KeyC");

    if (wasKeyCDown) onKeyUp("KeyC");
    if (wasControlDown) onKeyUp("ControlLeft");
  }, [onKeyDown, onKeyUp, pressedKeyCodes]);

  // imperative handle to expose some functions to the parent component and e.g. the streamer controls
  useImperativeHandle(ref, () => ({
    copyFromRemote: copyToClipboard,
    pasteToRemote,
    onWindowChanged,
  }));

  useEffect(() => {
    // effect to enable audio input (microphone) when the user wants to
    const _audioStream = audioStream.current;
    if (_audioStream !== null) {
      _audioStream.getTracks().forEach((track) => {
        track.enabled = !isMicrophoneMuted;
      });
    }

    return () => {
      if (_audioStream !== null) {
        _audioStream.getTracks().forEach((track) => {
          track.enabled = false;
        });
      }
    };
  }, [isMicrophoneMuted]);

  useEffect(() => {
    // effect to enable audio output (video) when the user wants to
    const _video = video.current;
    if (_video !== null) {
      _video.muted = isAudioMuted;
    }

    return () => {
      if (_video !== null) {
        _video.muted = true;
      }
    };
  }, [isAudioMuted]);

  useEffect(() => {
    // effect to enable fullscreen mode when the user wants to
    // on some devices, like mobile devices the real browser fullscreen has some limitations, e.g. on the type of interactions to be performed
    // special handling on iOS as fullscreen mode is not great there for interactivity
    if (isIOS) {
      if (isFullscreen) {
        // fullscreen mode is requested by the user
        // if we're not in fullscreen mode, we'll just maximize the video, but only if we're in landscape orientation (only for mobile)
        if (isMobile && !orientation.type.startsWith("landscape")) {
          // tell the user to turn the device
          toast({
            title: t("spectator.fullscreen.turn-to-enable-title"),
            description: t("spectator.fullscreen.turn-to-enable-description"),
            status: "info",
            duration: 5000,
          });
          setIsFullscreen(false);
        }
      } else {
        // should exit fullscreen mode as requested by the user
        if (isMobile && !orientation.type.startsWith("portrait")) {
          // tell the user to turn the device
          toast({
            title: t("spectator.fullscreen.turn-to-disable-title"),
            description: t("spectator.fullscreen.turn-to-disable-description"),
            status: "info",
            duration: 5000,
          });
        }
      }
      return;
    }

    const _video = video.current;
    if (!_video) {
      return;
    }

    if (isFullscreen) {
      // nothing to do if we're already in fullscreen mode
      if (document.fullscreenElement) {
        return;
      }

      // enter fullscreen mode as requested by the user
      if (_video.hasAttribute("controls")) {
        _video.removeAttribute("controls");
      }

      const container = fullscreenContainerRef.current;
      if (!document.fullscreenElement && container) {
        container
          .requestFullscreen()
          .then(() => {
            log.debug("Entered fullscreen mode");
          })
          .catch((error) => {
            log.error("Failed to enter fullscreen mode", error);
          });
      }
      document.onkeydown = (event: KeyboardEvent) => {
        onKeyDown(event.code);
      };
      document.onkeyup = (event: KeyboardEvent) => {
        onKeyUp(event.code);
      };
      document.onfullscreenchange = () => {
        if (!document.fullscreenElement) {
          // user exited fullscreen mode
          document.onkeydown = null;
          document.onkeyup = null;
          setIsFullscreen(false);
        }
      };
    } else {
      // nothing to do if we're not in fullscreen mode
      if (!document.fullscreenElement) {
        return;
      }

      // exit fullscreen mode as requested by the user
      document
        .exitFullscreen()
        .then(() => {
          log.debug("Exited fullscreen mode");
        })
        .catch((error) => {
          log.error("Failed to exit fullscreen mode", error);
        });
    }
  }, [dispatch, isFullscreen, onKeyDown, onKeyUp, setIsFullscreen, t, toast]);

  useEffect(() => {
    // automatically enable fullscreen mode on mobile devices when screen orientation changes to landscape mode
    const onOrientationChange = () => {
      // only do this on mobile devices, not tablets or desktops
      if (!isMobile) return;

      toggleFullscreen();
    };
    orientation.addEventListener("change", onOrientationChange);
    return () => {
      orientation.removeEventListener("change", onOrientationChange);
    };
  }, [toast, toggleFullscreen]);

  const isNonNativeFullscreen = isIOS && isFullscreen;

  return (
    <>
      <Global
        styles={{
          body: { height: ["100vh", "100dvh"], overflow: "hidden" },
        }}
      />
      <Flex
        css={
          isNonNativeFullscreen
            ? {
                height: ["100vh", "100dvh"],
                width: ["100vw", "100dvw"],
                top: 0,
                left: 0,
              }
            : undefined
        }
        width={width}
        height={height}
        position={isNonNativeFullscreen ? "fixed" : "relative"}
        zIndex={isNonNativeFullscreen ? "overlay" : undefined}
        bgColor={"chakra-body-bg"}
        flexDirection="column"
        justifyContent={["initial", "initial", "center"]}
        // prevent long touch press to bring up a context menu
        sx={{
          WebkitTouchCallout: "none !important",
          WebkitUserSelect: "none !important",
        }}
        {...props}
      >
        <Flex
          ref={fullscreenContainerRef}
          position={
            isFullscreen ? "relative" : isConnected ? undefined : "absolute"
          }
          justifyContent={["initial", "initial", "center"]}
          flexDirection="column"
          height={"full"}
          width={"full"}
          minHeight={{ base: 32, md: undefined }}
          sx={{ touchAction: "none" }}
          alignItems={"center"}
        >
          <Flex // min width and height are required for correct flex positioning
            minHeight={0}
            minWidth={0}
            objectFit="contain"
            position="relative"
            flexDirection="column"
          >
            <Video
              ref={video}
              muted={isAudioMuted}
              display={isConnected ? "block" : "none"}
              // to ensure the video can play without maximizing it on iPhones, add the playsinline property, see https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari#3030250
              playsInline
              tabIndex={-1}
              onMouseUp={onMouseEvent}
              onMouseMove={onMouseMove}
              onMouseDown={onMouseEvent}
              onWheel={onWheelEvent}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              onContextMenu={discardEvent}
              onClick={discardEvent}
              onKeyDown={onKeyDownReact}
              onKeyUp={onKeyUpReact}
              onMouseEnter={() => isConnected && video.current?.focus()}
              onMouseLeave={onFocusLost}
              outline="none"
              maxWidth={videoSize.width}
              maxHeight={videoSize.height}
              height={"full"}
              width={"full"}
            />

            <RemoteUrlOpenerOverlay
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={1}
            />
          </Flex>

          <ConnectionStateOverlay
            isConnected={isConnected}
            connectionState={connectionState}
            connectionError={connectionError}
            display={isConnected ? "none" : "flex"}
            position={isConnected ? "absolute" : "relative"}
          />

          <Flex
            width="full"
            height="full"
            zIndex={1}
            display={isNotReceivingFrames && isConnected ? "flex" : "none"}
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <VStack spacing={2} textAlign="center">
              <Icon zIndex={1} color="gray" as={InfoIcon} boxSize={16} />
              <Heading zIndex={1} fontSize="xl" color="gray">
                {t(`spectator.connection.no_frames`)}
              </Heading>
              <Heading zIndex={1} fontSize="l" color="gray">
                {t(`spectator.connection.no_frames_hint`)}
                <Link
                  href="https://innoactive.io/connection-issues"
                  target="_blank"
                >
                  https://innoactive.io/connection-issues
                </Link>
              </Heading>
            </VStack>
          </Flex>
          {isFullscreen ? (
            <Draggable
              bounds="body"
              handle=".handle"
              axis={isMobile ? "y" : "x"}
              defaultPosition={{ y: isMobile ? -50 : 0, x: 0 }}
            >
              <Stack
                position="absolute"
                right={0}
                bottom={0}
                padding={"1"}
                borderRadius={"4"}
                bgColor={"backgroundAlpha.700"}
                zIndex={1}
                direction={isMobile ? "column" : "row"}
              >
                <DragHandle
                  className="handle"
                  onTouchEnd={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                />
                <StreamerControls variant={"solid"} />
              </Stack>
            </Draggable>
          ) : null}
        </Flex>
      </Flex>
    </>
  );
});

function getCssCursorForWindowsCursor(value: string): string {
  switch (value.toUpperCase()) {
    case "IBEAM":
      return "text";
    case "WAITCURSOR":
      return "wait";
    case "SIZEWE":
      return "ew-resize";
    case "SIZENS":
      return "ns-resize";
    case "SIZEALL":
      return "all-scroll";
    case "HAND":
      return "pointer";
    case "CROSS":
      return "crosshair";
    case "UPARROW":
      return "n-resize";
    case "NO":
      return "not-allowed";
    default:
      return "auto";
  }
}
