import {
  ButtonGroup,
  Icon,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuDivider as MenuDividerBase,
  MenuDividerProps,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
} from "@chakra-ui/react";
import { UIEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaDownload as DownloadIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaExclamation as WarningIcon,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { openConfigurationDialog } from "../features/cloudRenderingSlice";
import {
  selectActiveDevice,
  selectIsDesktopClientConnected,
  setActiveDevice,
} from "../features/devicesSlice";
import {
  selectSessionServiceConnectionState,
  selectSessionState,
} from "../features/sessionSlice";
import {
  useAppDispatch,
  useAppPermissions,
  useAppProperties,
  useAppSelector,
  useAppSupportedActions,
  useApplicationQuery,
  useEncryptVrStreamSetting,
} from "../hooks";
import { useControlPanelUrl } from "../hooks/useControlPanelUrl";
import { SESSION_STATE } from "../session/session-state";
import {
  ClientDevice,
  DeviceType,
  SessionType as LaunchTarget,
} from "../session/types";
import { terminateSession } from "../signalR";
import {
  isAnyApplicationBuildInstalled,
  isApplicationBuildInstalled,
} from "../utils/applications";
import { downloadFile } from "../utils/downloadFile";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { CloudRenderingServiceStatus } from "./CloudRenderingServiceStatus";
import { LinkButton } from "./LinkButton";
import { RequestAccessButton } from "./RequestAccessButton";
import { SessionAlreadyRunningWarningDialog } from "./SessionAlreadyRunningWarningDialog";

import useResizeObserver from "use-resize-observer";
import {
  Application,
  ApplicationBuildId,
  ApplicationId,
  LaunchableApplicationLaunchConfiguration,
  XRPlatformType,
  androidXRPlatformTypes,
} from "../hooks/types";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { useSupportedDevices } from "../hooks/useSupportedDevices";
import { isWindows } from "../utils/browser-support";
import {
  doesSupportEncryptedVrStreaming,
  getXRPlatformForDevice,
} from "../utils/devices";
import { generateAppLaunchLink } from "../utils/routes";
import { XRPlatformDisplay } from "./XRPlatformDisplay";
import { ConfigurationIcon, DropdownIcon } from "./icons";

const MenuDivider = (props: MenuDividerProps) => (
  <MenuDividerBase _last={{ display: "none" }} {...props} />
);

enum DeviceIncompatibilityReason {
  Generic,
  VrStreamingEncryptionUnsupported,
}

interface AppActionProps {
  applicationId: ApplicationId;
}

function DeviceSelection({
  app,
  onClose,
}: {
  app: Application;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const activeDevice = useAppSelector(selectActiveDevice);
  const { isStandaloneVrApp } = useAppProperties(app);
  const supportedDevices = useSupportedDevices(app);
  const { t } = useTranslation();

  return (
    <>
      {!!supportedDevices.length && (
        <>
          <MenuOptionGroup
            value={activeDevice?.identifier}
            title={t("details.available_clients", {
              clients: isStandaloneVrApp
                ? t("details.vr_clients")
                : t("details.generic_clients"),
            })}
            onChange={(identifier) => {
              dispatch(setActiveDevice(identifier as string));
              onClose();
            }}
          >
            {supportedDevices.map((device) => (
              <MenuItemOption
                closeOnSelect={true}
                key={device.identifier}
                value={device.identifier}
              >
                {device.name}
                {isStandaloneVrApp &&
                  device.type === DeviceType.Standalone &&
                  // FIXME: for standalone devices, we don't know the specific build that is installed
                  (isAnyApplicationBuildInstalled(app.legacy_identity, device)
                    ? t("details.installed_addendum")
                    : t("details.not_installed_addendum"))}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
        </>
      )}
    </>
  );
}

function getApplicationBuildIdForLaunchTarget(
  application: Application | undefined,
  target: LaunchTarget | undefined,
  device: ClientDevice | undefined,
) {
  switch (target) {
    case LaunchTarget.LocallyRenderedWindows:
      return (
        application?.launch_configurations.find(({ xr_platform }) =>
          ["win-non-vr", "win-vr"].includes(xr_platform),
        )?.application_build ?? undefined
      );
    case LaunchTarget.CloudRenderedNonVR:
      return (
        application?.launch_configurations.find(
          ({ xr_platform }) => "win-non-vr" === xr_platform,
        )?.application_build ?? undefined
      );
    case LaunchTarget.LocallyRenderedStandalone:
      return (
        application?.launch_configurations.find(({ xr_platform }) => {
          const xrPlatformForDeviceType = getXRPlatformForDevice(device);
          return xr_platform === xrPlatformForDeviceType;
        })?.application_build ?? undefined
      );
    case LaunchTarget.CloudRenderedVR:
      return (
        application?.launch_configurations.find(
          ({ xr_platform }) => xr_platform === "win-vr",
        )?.application_build ?? undefined
      );
    default:
      return undefined;
  }
}

export function AppActions({ applicationId }: AppActionProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const connectionState = useAppSelector(selectSessionServiceConnectionState);
  const currentSession = useAppSelector(selectSessionState);
  const { t } = useTranslation();
  const { isSuccess, data: application } = useApplicationQuery(applicationId);
  const isDesktopClientConnected = useAppSelector(
    selectIsDesktopClientConnected,
  );
  const { ref: buttonGroupRef, width: menuWidth } = useResizeObserver();
  const activeDevice = useAppSelector(selectActiveDevice);
  const [autoCreateNewSession, setAutoCreateNewSession] = useState(false);
  const [nextLaunchTarget, setNextLaunchTarget] = useState<LaunchTarget | null>(
    null,
  );

  const [
    sessionAlreadyRunningWarningDialogShown,
    setSessionAlreadyRunningWarningDialogShown,
  ] = useState(false);

  const applicationPermissions = useAppPermissions(application);
  const userHasNoAccess = useMemo(
    () => !Object.values(applicationPermissions).some((perm) => perm),
    [applicationPermissions],
  );
  const supportedActions = useAppSupportedActions(application);
  const supportedDevices = useSupportedDevices(application);
  const encryptVrStreamSetting = useEncryptVrStreamSetting();

  // If the currently active HMD is not supported for this app, switch to a supported one automatically
  useEffect(() => {
    if (!supportedDevices.length) return;
    if (
      !supportedDevices.find(
        (hmd) => hmd.identifier === activeDevice?.identifier,
      )
    ) {
      dispatch(setActiveDevice(supportedDevices[0].identifier));
    }
    // we only want to run this once on the first render to not constantly override the user selected device
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSessionEnding = useMemo(
    () => currentSession.state === SESSION_STATE.ENDING,
    [currentSession],
  );
  const areMultipleActionsSupported =
    supportedActions.areMultipleActionsSupported;
  const isAnyActionSupported = supportedActions.isAnyActionSupported;
  // Device selection is required for VR cloud rendering or local rendering on standalone and desktop clients
  const isDeviceSelectionRequired = useMemo(
    () =>
      supportedActions.vrCloudRenderingSupported ||
      supportedActions.standaloneRenderingSupported ||
      supportedActions.desktopRenderingSupported,
    [supportedActions],
  );

  // Dropdown is required if either
  // - multiple actions are supported (to choose which action)
  // - action requires device selection
  const isDropdownActive =
    useMemo(
      () =>
        (supportedActions.standaloneRenderingSupported ||
          areMultipleActionsSupported ||
          // only show dropdown if a device can be selected
          (isDeviceSelectionRequired && supportedDevices.length > 1)) &&
        !userHasNoAccess,
      [
        areMultipleActionsSupported,
        isDeviceSelectionRequired,
        supportedActions.standaloneRenderingSupported,
        supportedDevices.length,
        userHasNoAccess,
      ],
    ) && !isSessionEnding;

  // The default launch target is on which device the app will be run if the button is just clicked
  // without making any further selection in the dropdown
  const defaultLaunchTarget = useMemo(() => {
    // priority is:
    // 1. Local Rendering (DC if DC is connected, Standalone HMD if standalone HMD is connected)
    // 2. VR Cloud Rendering
    // 3. Local Rendering (Desktop or Standalone Client)
    // 4. Non-VR Cloud Rendering
    if (
      activeDevice?.type === DeviceType.Desktop &&
      supportedActions.desktopRenderingSupported
    ) {
      return LaunchTarget.LocallyRenderedWindows;
    } else if (
      activeDevice?.type === DeviceType.Standalone &&
      supportedActions.standaloneRenderingSupported &&
      // FIXME: for standalone devices, we don't know the specific build that is installed
      isAnyApplicationBuildInstalled(application?.legacy_identity, activeDevice)
    ) {
      return LaunchTarget.LocallyRenderedStandalone;
    } else if (supportedActions.vrCloudRenderingSupported) {
      return LaunchTarget.CloudRenderedVR;
    } else if (supportedActions.launchInBrowserSupported) {
      return LaunchTarget.CloudRenderedNonVR;
    } else if (supportedActions.desktopRenderingSupported) {
      return LaunchTarget.LocallyRenderedWindows;
    } else if (supportedActions.standaloneRenderingSupported) {
      return LaunchTarget.LocallyRenderedStandalone;
    }
  }, [
    activeDevice,
    application,
    supportedActions.desktopRenderingSupported,
    supportedActions.launchInBrowserSupported,
    supportedActions.standaloneRenderingSupported,
    supportedActions.vrCloudRenderingSupported,
  ]);

  // when the current session ends, make sure to hide the abort session dialog
  useEffect(() => {
    if (!currentSession?.id) {
      setSessionAlreadyRunningWarningDialogShown(false);
    }
  }, [currentSession?.id]);

  const generateLaunchLink = useCallback(
    (launchTarget: LaunchTarget, targetDeviceIdentifier?: string) => {
      return (
        application &&
        generateAppLaunchLink({
          appId: application.id,
          type: launchTarget,
          deviceIdentifier: targetDeviceIdentifier,
        })
      );
    },
    [application],
  );

  const createNewSession = useCallback(
    (launchTarget?: LaunchTarget) => {
      if (!launchTarget) return;

      setSessionAlreadyRunningWarningDialogShown(false);
      const launchLink = generateLaunchLink(
        launchTarget,
        activeDevice?.identifier,
      );
      launchLink && navigate(launchLink);
    },
    [activeDevice?.identifier, generateLaunchLink, navigate],
  );

  const planNewSession = (launchTarget: LaunchTarget | undefined) => {
    if (!launchTarget) {
      return;
    }
    if (currentSession.id && currentSession.state < SESSION_STATE.ENDING) {
      setNextLaunchTarget(launchTarget);
      setSessionAlreadyRunningWarningDialogShown(true);
    } else {
      createNewSession(launchTarget);
    }
  };

  const hideAbortSessionDialog = () => {
    setAutoCreateNewSession(false);
    setSessionAlreadyRunningWarningDialogShown(false);
  };

  const overrideRunningSession = () => {
    terminateSession(currentSession.id!);

    // we clicked the "continue" button, this means we terminate the session now
    // the effect below will then create a new session
    setAutoCreateNewSession(true);
  };

  // to overwrite a session (start a new one while another one is still running)
  // we need to terminate the old session first. This takes a bit of time (SM needs to process this)
  // if the old session terminated, we can create a new one in this effect
  useEffect(() => {
    if (
      autoCreateNewSession &&
      nextLaunchTarget != null &&
      currentSession.state === SESSION_STATE.ENDED
    ) {
      createNewSession(nextLaunchTarget);
    }
  }, [
    currentSession.state,
    nextLaunchTarget,
    autoCreateNewSession,
    createNewSession,
  ]);

  const buttonMainActionLabel = useMemo(() => {
    const applicationBuildIdForLaunchTarget =
      getApplicationBuildIdForLaunchTarget(
        application,
        defaultLaunchTarget,
        activeDevice,
      );

    switch (defaultLaunchTarget) {
      case LaunchTarget.LocallyRenderedWindows:
        // if there's an active hmd and it is suitable for the selected launch action, show its name
        if (
          activeDevice &&
          supportedDevices.find(
            (device) => device.identifier === activeDevice?.identifier,
          )
        ) {
          if (
            isApplicationBuildInstalled(
              applicationBuildIdForLaunchTarget,
              activeDevice,
            )
          ) {
            return t("details.launch_on_device", {
              name: activeDevice?.name,
            });
          }
          return t("details.install_on_device", {
            name: activeDevice?.name,
          });
        }
        return t("details.launch_locally");

      case LaunchTarget.CloudRenderedNonVR:
        return t("details.launch_browser");

      case LaunchTarget.LocallyRenderedStandalone:
        // if there's an active hmd and it is suitable for the selected launch action, show its name
        if (
          supportedDevices.find(
            (device) => device.identifier === activeDevice?.identifier,
          )
        ) {
          if (
            activeDevice &&
            // FIXME: for standalone devices, we don't know the specific build that is installed
            !isAnyApplicationBuildInstalled(
              application?.legacy_identity,
              activeDevice,
            )
          ) {
            return t("details.not_installed_on_device", {
              name: activeDevice?.name,
            });
          }
          return t("details.launch_on_device", {
            name: activeDevice?.name,
          });
        }

        // otherwise, generic launch on device message
        return t("details.launch_on_standalone_device");

      case LaunchTarget.CloudRenderedVR:
        // if there's an active hmd and it is suitable for the selected launch action, show its name
        if (
          supportedDevices.find(
            (device) => device.identifier === activeDevice?.identifier,
          )
        ) {
          return t("details.stream_to_device", {
            name: activeDevice?.name,
          });
        }

        // otherwise, generic run in cloud action
        return t("details.launch_in_cloud");

      default:
        return t("details.unsupported");
    }
  }, [application, defaultLaunchTarget, activeDevice, supportedDevices, t]);

  const isDisconnected = useMemo(
    () => connectionState !== "Connected",
    [connectionState],
  );

  const doesDeviceSupportEncryptedVrStreaming = useMemo(() => {
    return activeDevice ? doesSupportEncryptedVrStreaming(activeDevice) : true;
  }, [activeDevice]);
  const isSelectedDeviceIncompatible = useMemo(() => {
    return (
      activeDevice?.type === DeviceType.Standalone &&
      !doesDeviceSupportEncryptedVrStreaming &&
      encryptVrStreamSetting
    );
  }, [
    activeDevice?.type,
    doesDeviceSupportEncryptedVrStreaming,
    encryptVrStreamSetting,
  ]);

  const appEditUrl = useControlPanelUrl(`/applications/${application?.id}`);
  const defaultLaunchLink = defaultLaunchTarget
    ? generateLaunchLink(defaultLaunchTarget)
    : undefined;

  return (
    <BrandedSkeleton isLoaded={isSuccess}>
      <ButtonGroup
        ref={buttonGroupRef}
        colorScheme="brand"
        variant="solid"
        isAttached={isDropdownActive}
      >
        {userHasNoAccess ? (
          <>
            {application && <RequestAccessButton application={application} />}
          </>
        ) : (
          <LinkButton
            onClick={(evt) => {
              evt.preventDefault();
              planNewSession(defaultLaunchTarget);
            }}
            isDisabled={
              // when there's no connection to session management, we cannot do anything
              isDisconnected ||
              // if there's no actions available
              !isAnyActionSupported ||
              // if the selected device is incompatible (e.g. doesn't support encryption)
              isSelectedDeviceIncompatible
            }
            isLoading={isSessionEnding}
            borderTopRightRadius={isDropdownActive ? 0 : undefined}
            borderBottomRightRadius={isDropdownActive ? 0 : undefined}
            borderRightWidth={1}
            borderRightStyle={isDropdownActive ? "solid" : "none"}
            borderRightColor="whiteAlpha.400"
            to={defaultLaunchLink ?? ""}
            paddingX={[4, 4, 8]}
          >
            {buttonMainActionLabel}
          </LinkButton>
        )}
        {isDropdownActive && (
          <Menu placement="bottom-end" closeOnSelect={true} variant="branded">
            {({ onClose }) => (
              <>
                <MenuButton
                  as={IconButton}
                  isLoading={isSessionEnding}
                  icon={<Icon as={DropdownIcon} />}
                  paddingInlineEnd={3}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  padding={[4, 4, 4, 4]}
                  disabled={isDisconnected}
                >
                  {t("header.language")}
                </MenuButton>
                <MenuList minWidth={menuWidth}>
                  {isDeviceSelectionRequired && application && (
                    <DeviceSelection app={application} onClose={onClose} />
                  )}
                  {supportedActions.vrCloudRenderingSupported && (
                    <>
                      <MenuItem
                        as={Link}
                        to={generateLaunchLink(LaunchTarget.CloudRenderedVR)}
                        onClick={() =>
                          planNewSession(LaunchTarget.CloudRenderedVR)
                        }
                      >
                        {t("details.launch_in_cloud")}
                      </MenuItem>
                    </>
                  )}
                  {supportedActions.launchInBrowserSupported && (
                    <>
                      <MenuItem
                        as={Link}
                        to={generateLaunchLink(LaunchTarget.CloudRenderedNonVR)}
                        onClick={() =>
                          planNewSession(LaunchTarget.CloudRenderedNonVR)
                        }
                      >
                        {t("details.launch_browser")}
                      </MenuItem>
                    </>
                  )}
                  {supportedActions.standaloneRenderingSupported &&
                    areMultipleActionsSupported &&
                    // only if main action label doesn't already show this
                    defaultLaunchTarget !==
                      LaunchTarget.LocallyRenderedStandalone && (
                      <>
                        <MenuItem
                          as={Link}
                          to={generateLaunchLink(
                            LaunchTarget.LocallyRenderedStandalone,
                          )}
                          onClick={() =>
                            planNewSession(
                              LaunchTarget.LocallyRenderedStandalone,
                            )
                          }
                        >
                          {t("details.launch_on_standalone_device")}
                        </MenuItem>
                      </>
                    )}
                  {supportedActions.desktopRenderingSupported &&
                    isWindows &&
                    !isDesktopClientConnected &&
                    // only if main action label doesn't already show this
                    defaultLaunchTarget !==
                      LaunchTarget.LocallyRenderedWindows && (
                      <>
                        <MenuItem
                          as={Link}
                          to={generateLaunchLink(
                            LaunchTarget.LocallyRenderedWindows,
                          )}
                        >
                          {t("details.launch_locally")}
                        </MenuItem>
                      </>
                    )}
                  {areMultipleActionsSupported && <MenuDivider />}
                  {application?.permissions.change && (
                    <>
                      <MenuItem
                        as="a"
                        href={appEditUrl}
                        target="_blank"
                        icon={<Icon as={ExternalLinkIcon} boxSize={3} />}
                      >
                        {t("details.edit")}
                      </MenuItem>
                      <MenuDivider />
                    </>
                  )}
                  {supportedActions.cloudRenderingSupported && (
                    <>
                      <MenuItem
                        onClick={() =>
                          dispatch(
                            openConfigurationDialog(
                              application?.launch_configurations.find(
                                (
                                  launchConfiguration,
                                ): launchConfiguration is LaunchableApplicationLaunchConfiguration =>
                                  !!launchConfiguration.application_build,
                              )?.application_build,
                            ),
                          )
                        }
                        icon={<Icon as={ConfigurationIcon} boxSize={3} />}
                      >
                        {t("details.streaming_preferences")}
                      </MenuItem>
                      <MenuDivider />
                    </>
                  )}
                  {supportedActions.standaloneRenderingSupported && (
                    <>
                      {application?.launch_configurations
                        .filter(
                          ({ xr_platform, application_build }) =>
                            androidXRPlatformTypes.includes(xr_platform) &&
                            application_build,
                        )
                        .map(
                          ({
                            xr_platform: xrPlatform,
                            application_build: applicationBuildId,
                          }) => (
                            <ApplicationBuildDownloadMenuItem
                              key={xrPlatform}
                              applicationBuildId={applicationBuildId!}
                              xrPlatform={xrPlatform}
                            />
                          ),
                        )}
                      <MenuDivider />
                    </>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </ButtonGroup>
      {!userHasNoAccess && (
        <SupportedActionsHint
          marginLeft={2}
          isDisconnected={isDisconnected}
          isAnyActionSupported={isAnyActionSupported}
        />
      )}
      {isSelectedDeviceIncompatible && (
        <DeviceIncompatibilityWarning
          marginLeft={2}
          reason={
            !doesDeviceSupportEncryptedVrStreaming
              ? DeviceIncompatibilityReason.VrStreamingEncryptionUnsupported
              : undefined
          }
        />
      )}
      <SessionAlreadyRunningWarningDialog
        isOpen={sessionAlreadyRunningWarningDialogShown}
        onClose={hideAbortSessionDialog}
        onConfirm={overrideRunningSession}
      />
    </BrandedSkeleton>
  );
}

const warningColor = "orange.300";
const errorColor = "red.300";

function DeviceIncompatibilityWarning({
  reason = DeviceIncompatibilityReason.Generic,
  ...props
}: {
  reason?: DeviceIncompatibilityReason;
} & Omit<IconButtonProps, "aria-label">) {
  const { t } = useTranslation();

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton
          variant="outline"
          color={warningColor}
          borderColor={warningColor}
          paddingX={2}
          size="xs"
          isRound
          icon={<Icon as={WarningIcon} boxSize={3} />}
          aria-label={t("launch.device_incompatible")}
          {...props}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{t("launch.device_incompatible")}</PopoverHeader>
        <PopoverBody>
          {reason === DeviceIncompatibilityReason.Generic
            ? t("launch.device_incompatible")
            : reason ===
                DeviceIncompatibilityReason.VrStreamingEncryptionUnsupported
              ? t("launch.device_not_supporting_encrypted_vr_streaming_error")
              : null}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function SupportedActionsHint({
  isDisconnected,
  isAnyActionSupported,
  ...props
}: {
  isAnyActionSupported: boolean;
  isDisconnected: boolean;
} & Omit<IconButtonProps, "aria-label">) {
  const { t } = useTranslation();

  if (isDisconnected) {
    return <CloudRenderingServiceStatus {...props} />;
  }

  if (!isAnyActionSupported) {
    return (
      <Popover trigger="hover">
        <PopoverTrigger>
          <IconButton
            variant="outline"
            color={isAnyActionSupported ? warningColor : errorColor}
            borderColor={isAnyActionSupported ? warningColor : errorColor}
            paddingX={2}
            size="xs"
            isRound
            icon={<Icon as={WarningIcon} boxSize={3} />}
            aria-label={"No actions available"}
            {...props}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            {t("details.no_actions_available_title")}
          </PopoverHeader>
          <PopoverBody>{t("details.no_actions_available")}</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return null;
}

function ApplicationBuildDownloadMenuItem({
  applicationBuildId,
  xrPlatform,
}: {
  applicationBuildId: ApplicationBuildId;
  xrPlatform: XRPlatformType;
}) {
  const { t } = useTranslation();
  const {
    data: applicationVersion,
    isLoading,
    isError,
    isSuccess,
  } = useApplicationBuildQuery(applicationBuildId);

  if (isLoading) {
    return (
      <MenuItem>
        <Skeleton>Loading ...</Skeleton>
      </MenuItem>
    );
  }

  if (isError) {
    return <MenuItem color="red">Error while loading</MenuItem>;
  }

  if (isSuccess) {
    return (
      <MenuItem
        as={"a"}
        href={applicationVersion.application_archive}
        onClick={(event: UIEvent) => {
          event.preventDefault();
          downloadFile(applicationVersion.application_archive);
        }}
        icon={<Icon as={DownloadIcon} boxSize={3} />}
      >
        {t("details.download_apk")} (
        <XRPlatformDisplay xrPlatform={xrPlatform} />)
      </MenuItem>
    );
  }

  return null;
}
