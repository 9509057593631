import { useColorModeValue } from "@chakra-ui/color-mode";
import { PopoverContent } from "@chakra-ui/popover";
import { popperCSSVars } from "@chakra-ui/popper";
import { PopoverContentProps } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/system";
import { ReactNode } from "react";

export function AppBarButtonPopoverContent({
  children,
  style = {},
  ...props
}: {
  style?: React.CSSProperties;
  children: ReactNode;
} & PopoverContentProps) {
  const borderColor = useColorModeValue("gray.600", "white");
  const [color] = useToken("colors", [borderColor]);

  return (
    <>
      <PopoverContent
        borderColor={borderColor}
        boxShadow="sm"
        css={{
          // bug in popover requires us to set the arrow color like this
          [popperCSSVars.arrowShadowColor.var]: color,
          ...style,
        }}
        {...props}
      >
        {children}
      </PopoverContent>
    </>
  );
}
