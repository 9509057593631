import { chakra, Image, StyleProps } from "@chakra-ui/react";
import _PortalLogo from "../assets/img/portal-logo.svg?react";
import { useBranding } from "../branding";
import { BrandedSkeleton } from "./BrandedSkeleton";

const PortalLogo = chakra(_PortalLogo);

export function Logo(props: StyleProps) {
  const { data: branding } = useBranding();
  const logo = branding?.logo;

  if (!logo) {
    // fallback logo
    return <PortalLogo fill="brand.500" paddingBottom={2} {...props} />;
  }

  return (
    <BrandedSkeleton isLoaded={!!logo}>
      <Image src={logo} alt="Logo" objectFit="contain" {...props} />
    </BrandedSkeleton>
  );
}
