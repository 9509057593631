import { useLocalStorage } from "usehooks-ts";

export type DcvPreferences = {
  streamingMode: "responsiveness" | "quality";
  highColorAccuracy: boolean;
};

export function useDcvPreferences() {
  return useLocalStorage<DcvPreferences>("dcvSettings", {
    streamingMode: "responsiveness",
    highColorAccuracy: false,
  });
}
