import { useMemo } from "react";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";

export function useIsReadyToStream() {
  const session = useAppSelector(selectSessionState);
  return useMemo(
    () => session.isActive && session.isCloudRendered,
    [session.isActive, session.isCloudRendered],
  );
}
