import { createContext } from "react";

interface ICloudRenderingSessionContext {
  requestAuthentication(): void;
  setLocalConnectionPollingInterval: (pollingInterval: number) => void;
  resetLocalConnectionPollingInterval: () => void;
}

export const DesktopClientContext = createContext(
  {} as ICloudRenderingSessionContext,
);
