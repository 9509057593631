import { ChakraProvider, Flex } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ColorModeConfiguration } from "./ColorModeConfiguration";
import { Login, Logout, OAuth2AuthCodeCallback } from "./auth";
import { useBranding } from "./branding";
import {
  AppBar,
  ErrorFallback,
  Footer,
  HMDOnlineStatusNotifications,
  Layout,
  Onboarding,
  RateSessionDialog,
  RequireAuth,
  RequireUser,
  SessionErrorHandler,
  SessionIsReadyDialog,
  SessionNotifications,
  StreamingPreferencesDialog,
  VmUpgradeNotification,
} from "./components";
import { usePortalName } from "./hooks/usePortalName";
import {
  ConnectDevicePage,
  DetailPage,
  FeatureFlagsPage,
  HomePage,
  InstallClientOverview,
  InstallClientPage,
  LaunchPageAdapter,
  LegacyLaunchPage,
  LegalPage,
  NoMatchPage,
  PrivacyPage,
  RemoteRenderingBackchannel,
  ServerLocationWizard,
  SessionPage,
  StandaloneInteractiveSpectatorPage,
  TermsPage,
} from "./pages";
import { brandTheme } from "./theme/theme";
import { routes } from "./utils/routes";
import { SentryRoutes } from "./utils/sentry";

/**
 * Redirect urls with trailing slashes to their counterpart w/o trailing slash
 */
function StripTrailingSlash() {
  const { pathname, ...props } = useLocation();

  return (
    <Navigate
      to={{
        ...props,
        pathname: pathname.slice(0, -1),
      }}
    />
  );
}

export default function App() {
  const { data: branding } = useBranding();

  const theme = useMemo(
    () =>
      brandTheme(
        branding?.primary ?? undefined,
        branding?.background ?? undefined,
      ),
    [branding?.primary, branding?.background],
  );

  return (
    <ChakraProvider theme={theme}>
      <ColorModeConfiguration />
      <Helmet>
        <title>{usePortalName()}</title>
        {branding?.product_icon && (
          <link rel="icon" type="image/png" href={branding.product_icon} />
        )}
        <meta name="theme-color" content={theme.colors.background["500"]} />
      </Helmet>
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <SentryRoutes>
            <Route
              path={routes.standaloneInteractiveSpectator}
              element={<StandaloneInteractiveSpectatorPage />}
            />
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route path={routes.login} element={<Login />} />
              <Route path="/legal" element={<LegalPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
            </Route>
            <Route
              element={
                <RequireAuth>
                  <Outlet />
                  <RequireUser>
                    <RateSessionDialog />
                    <SessionIsReadyDialog />
                    <SessionErrorHandler />
                    <HMDOnlineStatusNotifications />
                    <SessionNotifications />
                    <VmUpgradeNotification />
                    <StreamingPreferencesDialog />
                    <Onboarding />
                  </RequireUser>
                </RequireAuth>
              }
            >
              <Route
                path={routes.session}
                element={
                  <Flex
                    height={["100vh", "100dvh"]}
                    width={["100vw", "100dvw"]}
                    flexDirection={"column"}
                  >
                    <SessionPage />
                  </Flex>
                }
              />
              <Route
                element={
                  <Flex
                    minHeight="100vh"
                    direction="column"
                    maxW="8xl"
                    mx="auto"
                    // required to make up for "viewport-fit=cover" on mobile devices (e.g. iPhones); see https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
                    padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
                  >
                    <Outlet />
                  </Flex>
                }
              >
                <Route
                  path={routes.serverLocationWizard}
                  element={<ServerLocationWizard />}
                />
                <Route
                  path={routes.remoteRenderingBackchannel}
                  element={
                    <Flex
                      flexGrow={1}
                      alignSelf={"center"}
                      alignItems={"center"}
                    >
                      <RemoteRenderingBackchannel />
                    </Flex>
                  }
                />
                <Route
                  element={
                    <>
                      <AppBar maxW="8xl" />
                      <ErrorBoundary
                        fallback={({ error, resetError }) => (
                          <ErrorFallback
                            error={error}
                            resetErrorBoundary={resetError}
                          />
                        )}
                      >
                        <Outlet />
                      </ErrorBoundary>
                      <Footer />
                    </>
                  }
                >
                  <Route path="/:url*(/+)" element={<StripTrailingSlash />} />
                  <Route
                    element={
                      <RequireAuth>
                        <Outlet />
                      </RequireAuth>
                    }
                  >
                    <Route path={routes.home} element={<HomePage />} />
                    {[
                      routes.application.details,
                      routes.application.detailsViaGroup,
                    ].map((path) => (
                      <Route key={path} path={path} element={<DetailPage />} />
                    ))}
                  </Route>
                  <Route
                    element={
                      <Layout>
                        <Outlet />
                      </Layout>
                    }
                  >
                    <Route path="/flags" element={<FeatureFlagsPage />} />
                    {[routes.connectDevice, routes.legacy.connectHmd].map(
                      (path) => (
                        <Route
                          key={path}
                          path={path}
                          element={<ConnectDevicePage />}
                        />
                      ),
                    )}
                    <Route
                      path={routes.clients.install}
                      element={<InstallClientOverview />}
                    />
                    {[
                      routes.clients.installForDeviceSpecificType,
                      routes.clients.installForDevice,
                    ].map((path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<InstallClientPage />}
                      />
                    ))}
                    {[
                      routes.launch.legacy.app,
                      routes.launch.legacy.appViaGroup,
                    ].map((path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<LegacyLaunchPage />}
                      />
                    ))}
                    {[routes.launch.app, routes.launch.appViaGroup].map(
                      (path) => (
                        <Route
                          path={path}
                          key={path}
                          element={<LaunchPageAdapter />}
                        />
                      ),
                    )}
                  </Route>
                  <Route
                    path="/oauth2/callback"
                    element={<OAuth2AuthCodeCallback />}
                  />
                  <Route path={routes.logout} element={<Logout />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NoMatchPage />} />
          </SentryRoutes>
        </QueryParamProvider>
      </Router>
    </ChakraProvider>
  );
}
