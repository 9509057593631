import { Radio, RadioGroup, StyleProps, Text } from "@chakra-ui/react";
import {
  RowData,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { useAppSelector } from "../hooks";
import { CloudRenderingRegion } from "../hooks/types";
import { LatencyDisplay } from "./LatencyDisplay";
import { PaginatedTable } from "./PaginatedTable";
interface AvailableRegionsTableParams {
  regions?: CloudRenderingRegion[];
  preferredCloudRenderRegionName?: string;
  isLoading: boolean;
  setCloudRenderingRegion(region?: CloudRenderingRegion): void;
}

// see https://tanstack.com/table/v8/docs/api/core/column-def#meta
// strongly typed meta data for columns of table
declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    props?: StyleProps;
  }
}

// Define your row shape
type CloudRenderingRegionStats = {
  latency: number;
} & CloudRenderingRegion;

const columnHelper = createColumnHelper<CloudRenderingRegionStats>();

export function CloudRenderingRegionsTable({
  regions,
  preferredCloudRenderRegionName,
  isLoading,
  setCloudRenderingRegion,
}: AvailableRegionsTableParams) {
  const { t } = useTranslation();
  const cloudRendering = useAppSelector(selectCloudRendering);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        cell: (props) => {
          return (
            <Radio
              value={props.row.original.name}
              isDisabled={!props.row.original.isEnabled || isLoading}
            />
          );
        },
        meta: {
          props: { padding: 0, paddingLeft: 0.5 },
        },
      }),
      columnHelper.accessor("displayName", {
        header: () => t("streamingPreferences.map.region"),
        enableSorting: true,
        cell: (info) => {
          // if there's another region with the same name displayed in the table, append the cloud provider's name to the region name
          const displayName = info.getValue();
          const sameNameRegionExists = info.table
            .getRowModel()
            .rows.some(
              (row) =>
                row.original.name !== info.row.original.name &&
                row.original.displayName === displayName,
            );
          return (
            <Text as="b">
              {info.getValue()}
              {sameNameRegionExists && ` (${info.row.original.cloudProvider})`}
            </Text>
          );
        },
      }),
      columnHelper.accessor("distanceM", {
        header: () => t("streamingPreferences.map.distance"),
        cell: (info) => <>{(info.getValue() / 1000).toFixed()} km</>,
        enableSorting: true,
      }),
      columnHelper.accessor("latency", {
        header: () => t("streamingPreferences.map.latency"),
        cell: (info) => <LatencyDisplay latency={info.getValue()} />,
        enableSorting: true,
        sortUndefined: 1,
      }),
    ],
    [t, isLoading],
  );

  const data = useMemo(() => {
    if (!regions) {
      return Array(5).fill({ distanceM: 42 } as CloudRenderingRegionStats);
    }

    return regions.map<CloudRenderingRegionStats>((region) => ({
      ...region,
      latency: cloudRendering.regionsLatency[region?.name],
    }));
  }, [regions, cloudRendering.regionsLatency]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: 5 },
      sorting: [{ desc: false, id: "latency" }], // sort by latency per default
    },
    autoResetPageIndex: false, // prevent changing the page when new data comes in.
  });

  return (
    <RadioGroup
      onChange={(newSelectedRegion) =>
        setCloudRenderingRegion(data.find((r) => r.name === newSelectedRegion))
      }
      value={preferredCloudRenderRegionName}
      isDisabled={isLoading}
    >
      <PaginatedTable table={table} />
    </RadioGroup>
  );
}
