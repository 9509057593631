import { useMemo } from "react";
import { useEncryptedVrStreamPreference } from "./useEncryptedVrStreamPreference";
import { useOrganizationCloudRenderingPolicies } from "./useOrganizationCloudRenderingPolicies";

export function useEncryptVrStreamSetting(): boolean | undefined {
  const { data: policies, isSuccess } = useOrganizationCloudRenderingPolicies();
  const encryptVrStream = useEncryptedVrStreamPreference();

  return useMemo(() => {
    if (!isSuccess && encryptVrStream === undefined) {
      return undefined;
    }

    return policies?.cloudXREncryption === "Disallowed"
      ? false
      : policies?.cloudXREncryption === "Enforced" ||
          (encryptVrStream ?? policies?.cloudXREncryption === "OptOut");
  }, [encryptVrStream, isSuccess, policies?.cloudXREncryption]);
}
