import {
  CloseButton,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { MouseEventHandler, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "../../components/icons";
import { dismissUrl } from "../../features/sessionSlice";
import { useAppDispatch, useApplicationForSessionQuery } from "../../hooks";
import { KnownIssueCodes } from "../../session/types";
import { sendIssueHandled } from "../../signalR";
import { useRemoteUrls } from "./useRemoteUrls";

export function RemoteUrlOpenerOverlay(props: StyleProps) {
  const { data: application } = useApplicationForSessionQuery();
  const remoteUrls = useRemoteUrls(application?.id);
  const dispatch = useAppDispatch();
  const mostRecentUrl = useMemo(() => remoteUrls.at(-1), [remoteUrls]);

  const openUrl = useCallback(
    (remoteUrl: (typeof remoteUrls)[number]) => {
      const popup = window.open(
        remoteUrl.url,
        "portal-remote-url",
        "popup,noopener",
      );

      if (popup) {
        // don't show the URL to the user if it was opened successfully
        dispatch(dismissUrl(remoteUrl.id));
        // mark the issue as handled
        sendIssueHandled({
          id: remoteUrl.id,
          issueCode: KnownIssueCodes.OpenUrl,
          message: "URL was opened successfully",
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    // whenever a new url is tried to be opened, try to automatically open it
    if (mostRecentUrl && mostRecentUrl.prompt) {
      openUrl(mostRecentUrl);
    }
  }, [mostRecentUrl, openUrl]);

  return (
    <Flex
      bgColor="rgba(0, 0, 0, 0.5)"
      alignItems={"center"}
      justifyContent={"center"}
      display={
        remoteUrls.filter(({ prompt }) => !!prompt).length > 0 ? "flex" : "none"
      }
      {...props}
    >
      <Stack spacing={2} width="full">
        {remoteUrls
          .filter(({ prompt }) => !!prompt)
          .map((remoteUrl) => (
            <RemoteUrlDisplay
              key={remoteUrl.id}
              url={remoteUrl.url}
              onDismiss={() => dispatch(dismissUrl(remoteUrl.id))}
              onClick={(e) => {
                e.preventDefault();
                openUrl(remoteUrl);
              }}
            />
          ))}
      </Stack>
    </Flex>
  );
}

function RemoteUrlDisplay({
  url,
  onDismiss,
  onClick,
}: {
  url: string;
  onDismiss: () => void;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}) {
  const { t } = useTranslation();

  return (
    <Stack
      padding={2}
      spacing={2}
      borderRadius={"md"}
      backgroundColor="chakra-subtle-bg"
      marginX={4}
      alignItems={"center"}
      border="1px"
      borderColor={"chakra-border-color"}
      position={"relative"}
      fontSize={"sm"}
    >
      <CloseButton
        size="sm"
        position={"absolute"}
        right={1}
        top={1}
        onClick={onDismiss}
      />
      <Text marginRight={4}>{t("spectator.remoteUrls.text")}</Text>
      <Link href={url} isExternal onClick={onClick} maxWidth={"full"}>
        <HStack padding={2}>
          <Icon as={ExternalLinkIcon} />
          <Text
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {url}
          </Text>
        </HStack>
      </Link>
    </Stack>
  );
}
