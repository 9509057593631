import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { setUserPreferences } from "../session-management-api";
import { UserPreferences } from "./types";
import { userPreferencesQueryKey } from "./useUserPreferences";

export function useUpdateUserPreferencesMutation(
  options: Partial<
    Omit<
      UseMutationOptions<
        UserPreferences,
        AxiosError,
        UserPreferences,
        UserPreferences
      >,
      "mutationFn"
    >
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation<
    UserPreferences,
    AxiosError<unknown, unknown>,
    UserPreferences,
    UserPreferences
  >({
    mutationFn: (preferences: UserPreferences) =>
      setUserPreferences(preferences),
    onMutate: (userPreferences) => {
      // optimistically update the cache
      // this will be reverted if the mutation fails
      // remember the current data
      const previousUserPreferences = queryClient.getQueryData<UserPreferences>(
        userPreferencesQueryKey,
      );

      queryClient.setQueryData(userPreferencesQueryKey, userPreferences);

      return previousUserPreferences;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userPreferencesQueryKey });
    },
    onError: (error, variables, context) => {
      // revert the optimistic update
      queryClient.setQueryData(
        userPreferencesQueryKey,
        context as UserPreferences,
      );
    },
    ...options,
  });
}
