import { Box, Button, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DialogContainer } from "../../components";
import { InfoIcon } from "../../components/icons";
import { Organization } from "../../hooks/types";
import { useOrganizationQuery } from "../../hooks/useActiveOrganizationQuery";

export function SessionFromDifferentOrganizationWarning({
  abortSession,
  organizationId,
}: {
  abortSession: () => void;
  organizationId: Organization["id"];
}) {
  const { t } = useTranslation();
  const organizationQuery = useOrganizationQuery(organizationId);
  const sessionUrlInOtherOrganization = useMemo(() => {
    if (!organizationQuery.data?.domain) {
      return "#";
    }

    return window.location.href.replace(
      window.location.host,
      organizationQuery.data?.domain,
    );
  }, [organizationQuery.data?.domain]);

  const translationContext = {
    organizationName: organizationQuery.data?.name,
  };

  return (
    <Box width={"container.sm"} margin="auto">
      <DialogContainer>
        <Stack spacing={4} alignItems={"center"}>
          <Icon as={InfoIcon} boxSize={20} />

          <Stack alignItems="center" textAlign={"center"}>
            <Text>
              {t("session.switch_organization_info", translationContext)}
            </Text>
          </Stack>
          <HStack justifyContent={"center"}>
            <Button
              isLoading={organizationQuery.isLoading}
              as="a"
              cursor={"pointer"}
              href={sessionUrlInOtherOrganization}
            >
              {t("session.switch_organization", translationContext)}
            </Button>
            <Button onClick={abortSession} colorScheme="red">
              {t("session.end")}
            </Button>
          </HStack>
        </Stack>
      </DialogContainer>
    </Box>
  );
}
