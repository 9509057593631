import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { SessionDataExtended } from "../../features/sessionSlice";
import { InteractiveSpectator } from "../../streamer/interactive-spectator/InteractiveSpectator";
import { SessionPreviewApplicationImage } from "./SessionPreviewApplicationImage";
import { SessionPreviewOverlay } from "./SessionPreviewOverlay";

export function SessionPreview({ session }: { session: SessionDataExtended }) {
  const isInteractiveSpectatorActive = useMemo(
    () => session.isActive && (session.isBrowser || session.isCloudRendered),
    [session.isActive, session.isBrowser, session.isCloudRendered],
  );
  return (
    <Flex
      position="relative"
      overflow="hidden"
      flexGrow="1"
      minHeight={["20vh", "30vh", undefined] as unknown as string}
      flexDirection="column"
    >
      {isInteractiveSpectatorActive && session.id && session.ipAddress ? (
        <InteractiveSpectator
          sessionId={session.id}
          host={session.ipAddress}
          height="100%"
        />
      ) : (
        <SessionPreviewApplicationImage
          applicationBuildId={session.applicationBuildId}
          display={{
            base: session.isActive ? "none" : "flex",
            md: "flex",
          }}
        />
      )}
      {!isInteractiveSpectatorActive && (
        <SessionPreviewOverlay session={session} />
      )}
    </Flex>
  );
}
