import { selectRenderingServerIp } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { InteractiveSpectator } from "../streamer/interactive-spectator/InteractiveSpectator";
import { SignalerType } from "../streamer/interactive-spectator/Signaler";
import { StreamerContextProvider } from "../streamer/StreamerContext";

export function StandaloneInteractiveSpectatorPage() {
  const serverIpFromQueryParams =
    new URLSearchParams(window.location.search).get("serverIp") ?? "127.0.0.1";
  const serverIpAddress =
    useAppSelector(selectRenderingServerIp) ?? serverIpFromQueryParams;
  const params = new URLSearchParams(window.location.search);
  const signalingType =
    (params.get("signaling") as SignalerType) ??
    SignalerType.WebsocketSelfhosted;

  return (
    <StreamerContextProvider>
      <InteractiveSpectator
        host={serverIpAddress}
        signaling={signalingType}
        width={"100vw"}
        height={"100vh"}
      />
    </StreamerContextProvider>
  );
}
