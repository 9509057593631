import log from "loglevel";
import { useMemo } from "react";
import { RenderMode, TargetDeviceType } from "../pages/launch/types";
import { ClientDevice, SessionType } from "../session/types";
import { ClientDeviceType, getDeviceType } from "../utils/devices";
import {
  Application,
  LaunchableApplicationLaunchConfiguration,
  androidXRPlatformTypes,
} from "./types";

export function useLaunchConfigurationCandidates(
  validLaunchConfigurations: LaunchableApplicationLaunchConfiguration[],
  sessionType: SessionType,
  targetDevice: ClientDevice | undefined,
) {
  return useMemo(
    () =>
      validLaunchConfigurations.filter(
        (
          { xr_platform: xrPlatform, application_build: buildId },
          _,
          _validLaunchConfigurations,
        ) => {
          // special case for browser based streaming --> no target device needs to be connected (target device is the browser itself)
          if (sessionType === SessionType.CloudRenderedNonVR) {
            return xrPlatform === "win-non-vr";
          }

          // special case for desktop based launching --> we'll connect the local PC as the target device
          if (sessionType === SessionType.LocallyRenderedWindows) {
            if (xrPlatform !== "win-vr" && xrPlatform !== "win-non-vr") {
              // only windows builds are eligible for locally running it on Windows
              return false;
            }

            // make sure to only return the VR build though, if there's also a non-vr build and it's the same
            if (xrPlatform === "win-non-vr") {
              return (
                _validLaunchConfigurations.filter(
                  ({ xr_platform, application_build }) =>
                    xr_platform === "win-vr" && application_build === buildId,
                ).length === 0
              );
            }

            // any other (windows) build may be eligible for running on windows
            return true;
          }

          // for all other cases, we need a target device
          const device = targetDevice;
          if (!device) {
            return false;
          }

          const deviceType = getDeviceType(device);
          switch (xrPlatform) {
            case "quest":
              return deviceType === ClientDeviceType.Quest;
            case "wave":
              return deviceType === ClientDeviceType.Wave;
            case "pico":
              return deviceType === ClientDeviceType.Pico;
            case "apple":
              return deviceType === ClientDeviceType.AVP;
            case "vrx":
              log.warn("Unsupported XR platform: vrx");
              // Currently unsupported by standalone devices
              return false;
            case "win-vr":
              return deviceType !== undefined;
            default:
              return false;
          }
        },
      ),
    [sessionType, targetDevice, validLaunchConfigurations],
  );
}

export function useLaunchConfigurationsForLaunch(
  application: Application | undefined,
  renderMode: RenderMode | undefined,
  targetDeviceType: TargetDeviceType | undefined,
) {
  return useMemo(() => {
    return application && renderMode && targetDeviceType
      ? getLaunchConfigurationsForLaunch(
          application,
          renderMode,
          targetDeviceType,
        )
      : [];
  }, [application, renderMode, targetDeviceType]);
}

export function getLaunchConfigurationsForLaunch(
  application: Pick<Application, "launch_configurations" | "id">,
  renderMode: RenderMode,
  targetDeviceType: TargetDeviceType,
): LaunchableApplicationLaunchConfiguration[] {
  const matchingLaunchConfigurations = application.launch_configurations.filter(
    (
      launchConfiguration,
    ): launchConfiguration is LaunchableApplicationLaunchConfiguration => {
      const { xr_platform: xrPlatform, application_build: buildId } =
        launchConfiguration;
      if (!buildId) return false;

      if (renderMode === "local") {
        if (targetDeviceType === "standalone") {
          return androidXRPlatformTypes.includes(xrPlatform);
        } else if (targetDeviceType === "windows") {
          return xrPlatform.startsWith("win-");
        }
      } else if (renderMode === "cloud") {
        if (targetDeviceType === "standalone") {
          return xrPlatform === "win-vr";
        } else if (targetDeviceType === "browser") {
          return xrPlatform === "win-non-vr";
        }
      }

      return false;
    },
  );

  if (matchingLaunchConfigurations.length === 0) {
    log.warn(
      `No matching launch configurations found for application ${application.id} with render mode ${renderMode} and target device type ${targetDeviceType}`,
    );
  } else if (matchingLaunchConfigurations.length > 1) {
    log.warn(
      `Multiple matching launch configurations found for application ${application.id} with render mode ${renderMode} and target device type ${targetDeviceType}`,
    );
  }
  return matchingLaunchConfigurations;
}
