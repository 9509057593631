import {
  Icon,
  IconButton,
  IconButtonProps,
  forwardRef,
} from "@chakra-ui/react";
import { AudioMutedIcon, AudioUnmutedIcon } from "../../components/icons";

export const AudioButton = forwardRef(
  (
    {
      isAudioMuted,
      toggleAudioOutput,
      ...props
    }: {
      isAudioMuted: boolean;
      toggleAudioOutput: () => void;
    } & Pick<IconButtonProps, "isDisabled">,
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        onClick={toggleAudioOutput}
        icon={<Icon as={isAudioMuted ? AudioMutedIcon : AudioUnmutedIcon} />}
        paddingX={4}
        {...{ "aria-label": isAudioMuted ? "Unmute Audio" : "Mute Audio" }}
        {...props}
      />
    );
  },
);
