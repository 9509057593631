import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFeatureFlags } from "../hooks";
import { useDcvPreference } from "./useDcvPreference";

export function StreamingTechnologyPreferences() {
  const { t } = useTranslation();
  const useDcvForBrowserStreaming = useDcvPreference();
  const [, setFlags] = useFeatureFlags();

  return (
    <FormControl as="fieldset">
      <Stack spacing={4} paddingTop={4}>
        <HStack>
          <Switch
            id="use-dcv"
            isChecked={useDcvForBrowserStreaming}
            onChange={(evt) =>
              setFlags((flags) => ({
                ...flags,
                useDcvForBrowserStreaming: evt.target.checked,
              }))
            }
          />
          <FormLabel htmlFor="use-dcv" margin={0}>
            {t("streamingPreferences.protocols.use_dcv")}
          </FormLabel>
        </HStack>
        <FormHelperText marginTop={0}>
          {t("streamingPreferences.protocols.use_dcv_help_text_disabled")}
        </FormHelperText>
      </Stack>
    </FormControl>
  );
}
