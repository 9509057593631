import { ClientApplicationType, XRPlatformType } from "../hooks/types";
import { ClientDevice } from "../session/types";

export enum ClientDeviceType {
  Quest,
  Wave,
  Pico,
  VRX,
  AVP,
}

export function getDeviceType(device: ClientDevice) {
  if (!device.vendorName) return undefined;
  const vendorName = device.vendorName.toLowerCase();
  if (vendorName === "oculus" || vendorName === "meta") {
    return ClientDeviceType.Quest;
  } else if (vendorName === "htc") {
    return ClientDeviceType.Wave;
  } else if (vendorName === "pico") {
    return ClientDeviceType.Pico;
  } else if (vendorName === "lenovo") {
    return ClientDeviceType.VRX;
  } else if (vendorName === "apple") {
    return ClientDeviceType.AVP;
  }
}

export function getXRPlatformForDevice(
  device: ClientDevice | undefined,
): XRPlatformType | undefined {
  if (!device) return undefined;
  switch (getDeviceType(device)) {
    case ClientDeviceType.Quest:
      return "quest";
    case ClientDeviceType.Wave:
      return "wave";
    case ClientDeviceType.Pico:
      return "pico";
    case ClientDeviceType.VRX:
      return "vrx";
    case ClientDeviceType.AVP:
      return "apple";
    default:
      return undefined;
  }
}

/**
 * Returns the type of Portal client application that will work with the device
 * @param device the device to check
 * @returns the suitable type of client application that will work with the device
 */
export function getClientApplicationTypeForDevice(device: ClientDevice) {
  const deviceType = getDeviceType(device);
  switch (deviceType) {
    case ClientDeviceType.Quest:
      return ClientApplicationType.MetaQuest;
    case ClientDeviceType.Wave:
      return ClientApplicationType.HtcViveFocus3;
    case ClientDeviceType.Pico:
      return ClientApplicationType.PicoNeo3Pro;
    case ClientDeviceType.AVP:
      return ClientApplicationType.AppleVisionPro;
    default:
      return undefined;
  }
}

export function doesSupportEncryptedVrStreaming(device: ClientDevice) {
  return !!device.wireguardPublicKey;
}
