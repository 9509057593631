import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { type DcvPreferences, useDcvPreferences } from "./useDcvPreferences";

export default function DcvSettingsManager() {
  const { t } = useTranslation();
  const [dcvSettings, setDcvSettings] = useDcvPreferences();

  return (
    <Stack spacing={6}>
      <FormControl>
        <FormLabel fontWeight={"semibold"}>
          {t("streamer.dcv.settings.streaming_mode")}
        </FormLabel>
        <RadioGroup
          value={dcvSettings.streamingMode}
          onChange={(value) =>
            setDcvSettings({
              ...dcvSettings,
              streamingMode: value as DcvPreferences["streamingMode"],
            })
          }
        >
          <Stack>
            <Radio value="responsiveness">
              <Stack spacing={0}>
                <Text>{t("streamer.dcv.settings.mode_responsiveness")}</Text>
                <FormHelperText marginTop={0}>
                  {t("streamer.dcv.settings.mode_responsiveness_help")}
                </FormHelperText>
              </Stack>
            </Radio>
            <Radio value="quality">
              <Stack spacing={0}>
                <Text>{t("streamer.dcv.settings.mode_quality")}</Text>
                <FormHelperText marginTop={0}>
                  {t("streamer.dcv.settings.mode_quality_help")}
                </FormHelperText>
              </Stack>
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel fontWeight={"semibold"}>
          {t("streamer.dcv.settings.high_color_accuracy")}
        </FormLabel>
        <HStack>
          <Switch
            id="high-color-accuracy"
            isChecked={dcvSettings.highColorAccuracy}
            size="sm"
            onChange={(event) => {
              setDcvSettings((value) => ({
                ...value,
                highColorAccuracy: event.target.checked,
              }));
            }}
          />
          <Stack spacing={0}>
            <FormLabel htmlFor="high-color-accuracy" mb={0}>
              {dcvSettings.highColorAccuracy
                ? t("streamer.dcv.settings.high_color_accuracy_enabled")
                : t("streamer.dcv.settings.high_color_accuracy_disabled")}
            </FormLabel>
            <FormHelperText marginTop={0}>
              {t("streamer.dcv.settings.high_color_accuracy_help")}
            </FormHelperText>
          </Stack>
        </HStack>
      </FormControl>
    </Stack>
  );
}
