import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import type { UserPreferences } from "../hooks/types";
import { getUserPreferences } from "../session-management-api";

export const userPreferencesQueryKey = ["user-preferences"];

export function useUserPreferencesQuery(
  options: Omit<UseQueryOptions<UserPreferences>, "queryKey"> = {},
) {
  const { user } = useAuthentication();

  return useQuery<UserPreferences>({
    queryKey: userPreferencesQueryKey,
    queryFn: getUserPreferences,
    retry: false,
    enabled: !!user,
    ...options,
  });
}
