import {
  createListenerMiddleware,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { sendSessionActivity } from "../signalR";
import type { RootState } from "../store";

export interface InteractiveSpectatorState {
  connectionState?: RTCPeerConnectionState;
  connectionError?: string;
  isConnected: boolean;
  isNotReceivingFrames: boolean;
  pressedKeyCodes: string[];
  downedMouseButtonIds: number[];
}

const initialState: InteractiveSpectatorState = {
  isConnected: false,
  isNotReceivingFrames: false,
  pressedKeyCodes: [],
  downedMouseButtonIds: [],
};

export const interactiveSpectatorSlice = createSlice({
  name: "interactiveSpectator",
  initialState,
  reducers: {
    setConnectionState: (
      state,
      action: PayloadAction<RTCPeerConnectionState>,
    ) => {
      state.connectionState = action.payload;
      state.connectionError =
        action.payload === "failed" ? "Connection failed" : undefined;
      state.isConnected = action.payload === "connected";
    },
    setIsNotReceivingFrames: (state, action: PayloadAction<boolean>) => {
      state.isNotReceivingFrames = action.payload;
    },
    addPressedKeyCode: (state, action: PayloadAction<string>) => {
      state.pressedKeyCodes = [
        ...Array.from(new Set([...state.pressedKeyCodes, action.payload])),
      ];
    },
    removePressedKeyCode: (state, action: PayloadAction<string>) => {
      state.pressedKeyCodes = state.pressedKeyCodes.filter(
        (keyCode) => keyCode !== action.payload,
      );
    },
    resetPressedKeyCodes: (state) => {
      state.pressedKeyCodes = [];
    },
    resetDownedMouseButtons: (state) => {
      state.downedMouseButtonIds = [];
    },
    updateDownedMouseButton: (
      state,
      action: PayloadAction<{ buttonId: number; change: "up" | "down" }>,
    ) => {
      state.downedMouseButtonIds =
        action.payload.change === "down"
          ? // add the pressed button to the list of mouse buttons currently pressed
            [
              ...Array.from(
                new Set([
                  ...state.downedMouseButtonIds,
                  action.payload.buttonId,
                ]),
              ),
            ]
          : // remove the released button from the list of mouse buttons currently pressed
            state.downedMouseButtonIds.filter(
              (buttonId) => buttonId !== action.payload.buttonId,
            );
    },
    mouseMovement: () => {},
  },
});

export const {
  addPressedKeyCode,
  removePressedKeyCode,
  resetDownedMouseButtons,
  resetPressedKeyCodes,
  setConnectionState,
  setIsNotReceivingFrames,
  updateDownedMouseButton,
  mouseMovement,
} = interactiveSpectatorSlice.actions;

export const selectInteractiveSpectator = (state: RootState) =>
  state.interactiveSpectator;

export const selectInteractiveSpectatorConnectionState = createSelector(
  selectInteractiveSpectator,
  (state) => ({
    isConnected: state.isConnected,
    connectionState: state.connectionState,
    connectionError: state.connectionError,
  }),
);

export default interactiveSpectatorSlice.reducer;

// Middleware to start latency checks and save the latency info to local storage
const listenerMiddleware = createListenerMiddleware<{
  interactiveSpectator: InteractiveSpectatorState;
}>();

listenerMiddleware.startListening({
  matcher: isAnyOf(
    addPressedKeyCode,
    removePressedKeyCode,
    resetDownedMouseButtons,
    resetPressedKeyCodes,
    updateDownedMouseButton,
    mouseMovement,
  ),
  effect: async (_action, listenerApi) => {
    // wait for 5 seconds before sending the session activity
    await listenerApi.delay(5000);
    const state = listenerApi.getState() as RootState;

    if (!state.session.sessionState.id) {
      // no active session
      return;
    }

    if (!state.session.sessionState.appLaunched) {
      // the app is not launched
      return;
    }

    // cancel all other listeners to ensure that just a single session activity is sent
    listenerApi.cancelActiveListeners();
    // send the session activity
    sendSessionActivity(state.session.sessionState.id);
  },
});

export const sessionActivityMiddleware = listenerMiddleware.middleware;
