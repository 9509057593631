import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Container,
  Flex,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "../auth";
import { routes } from "../utils/routes";

export function RequireUser({ children }: PropsWithChildren) {
  const { user } = useAuthentication();

  if (user) {
    return <>{children}</>;
  }

  return null;
}

export function RequireAuth({ children }: PropsWithChildren): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const location = useLocation();

  // if we are currently authenticating / authorizing, wait for it to finish before doing anything
  if (auth.isPendingAuthentication) {
    return (
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
        <Container>
          <Alert status="info" marginTop={20}>
            <AlertIcon />
            {t("authorization_in_progress")}
          </Alert>
        </Container>
      </Flex>
    );
  }

  if (auth.error) {
    return (
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
        <Container>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            marginTop={20}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t("authorization_error")}
            </AlertTitle>
            <AlertDescription maxWidth="sm">{auth.error} </AlertDescription>
          </Alert>
        </Container>
      </Flex>
    );
  }

  if (!auth.user) {
    return <Navigate to={routes.login} state={{ from: location }} />;
  }

  // all good, render children
  return <>{children}</>;
}
